import styled from "@emotion/styled"
import { darkBlueColor } from "styles"

export const Content = styled.div`
  text-align: center;
  margin-bottom: 100px;
`

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px 10px 10px;
  text-align: justify;

  h2 {
    font-size: 18px;
    line-height: 21px;
  }

  h2:last-of-type {
    margin: 6px 0 20px 0;
  }

  p,
  li {
    font-size: 13px;
    line-height: 24px;
    color: ${darkBlueColor};
  }
`

export const iconStyle = {
  marginBottom: "20px"
}
