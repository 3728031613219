import styled from "@emotion/styled"
import { TransitionGroup } from "react-transition-group"

export const FieldsContainer = styled(TransitionGroup)<{
  margin: string
}>`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
  gap: 20px;
`
