import styled from "@emotion/styled"
import { darkBlueColor } from "styles"

export const CarouselContainer = styled.div`
  width: 100%;
`

export const CarouselTitle = styled.h1`
  margin-top: 3rem;
  color: ${darkBlueColor};
  font-size: 23px;
  line-height: 33px;
  font-weight: 700;
  padding-right: 200px;
`
