import styled from "@emotion/styled"
import { darkBlueColor, whiteColor } from "styles"

export const ContentContainer = styled.div<{
  background: string
  isMobileOrTablet: boolean
  textColor: string
}>`
  padding: ${(props) => (props.isMobileOrTablet ? "200px 20px 175px 20px" : "200px 20px 20px 20px")};
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  background: ${(props) => props.background};
  border-radius: 20px;

  h1 {
    font-size: 35px;
    line-height: 45px;
    color: ${(props) => props.textColor || "initial"};
  }

  li {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    margin-top: -15px;
    line-height: 30px;
    color: ${(props) => props.textColor || "initial"};
  }
`

export const TitleContainer = styled.div`
  margin-bottom: 15px;
`

export const ButtonsContainer = styled.div<{
  backgroundTopColor: string
  backgroundBottomColor: string
  isMobileOrTablet: boolean
}>`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: ${(props) => (props.isMobileOrTablet ? "fixed" : "relative")};
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${(props) => (props.isMobileOrTablet ? "100px 20px 20px 20px;" : "20px")};
  z-index: 2;
  background: ${(props) =>
    props.isMobileOrTablet
      ? `linear-gradient(180deg, rgba(${props.backgroundTopColor}, 0) 0%, ${props.backgroundBottomColor} 53%);`
      : "none"};
`

export const changeButtonStyle = {
  backgroundColor: "transparent",
  border: `1px solid ${whiteColor}`
}

export const continueButtonStyle = {
  flex: 1,
  backgroundColor: whiteColor,
  color: darkBlueColor,
  "&:hover": {
    backgroundColor: whiteColor,
    color: darkBlueColor
  }
}
