import styled from "@emotion/styled"
export const Container = styled.div<{ marginTop?: number; marginLeft?: string }>`
  margin-top: ${({ marginTop }) => marginTop ?? 25}px;
  display: flex;
  margin-left: ${({ marginLeft }) => marginLeft ?? 25}px;
`

export const boxStyle = {
  display: "flex",
  alignItems: "center"
}
