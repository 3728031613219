import React, { useContext, useEffect, useMemo, useState } from "react"
import { FieldVariation, Loading } from "components"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import {
  constructCurrencySlice,
  getCurrencies,
  getFieldValue,
  getLocalStorage,
  goToPreviousStep,
  LOCAL_STORAGE_PLATFORM_BACKEND,
  LOCAL_STORAGE_PLATFORM_CURRENCIES,
  SignupStepType
} from "utils"
import { useSignupData } from "hooks"
import { LanguageContext } from "context/LanguageContext"
import { signupApi } from "redux/features/signupapi/signupApi"

const AccountCurrency = (props: SignupStepType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [finalSlices, setFinalSlices] = useState<any>(null)
  const { signupData, continueToNextStep, getCurrentSignupData, updateCurrentStepSignupData, currentStepSignupData } =
    useSignupData()

  const { language } = useContext(LanguageContext)

  const { slices, uid, prevStep } = props

  const countryCode = getFieldValue(signupData, "country_of_residence")
  const [getPlatformsFromBackend, { isFetching: isFetchingPlatformsBackend }] =
    signupApi.endpoints.getBackendByCountry.useLazyQuery()
  const [platformBackend, setPlatformBackend] = useState(getLocalStorage(LOCAL_STORAGE_PLATFORM_BACKEND) || null)
  const fetchPlatforms = async () => {
    if (!platformBackend) {
      const platformsFromBackend = await getPlatformsFromBackend({
        countryCode
      }).unwrap()
      setPlatformBackend(platformsFromBackend)
    }
  }
  useEffect(() => {
    fetchPlatforms()
  }, [])

  const platformCurrencies = Object.keys(
    getLocalStorage(LOCAL_STORAGE_PLATFORM_CURRENCIES) || signupData.platform_currencies.platform_currency
  )

  useEffect(() => {
    const endSlices = slices.map((slice) => {
      // keep the slices that are not card groups
      if (slice.variation !== FieldVariation.CardGroup) {
        return slice
      } else {
        return null
      }
    })

    const noNulls = endSlices.filter((eachItem) => eachItem !== null)

    const platformCurrenciesObject = []
    if (platformBackend) {
      platformCurrencies.forEach((eachCurr) => {
        platformCurrenciesObject.push({
          currencyName: eachCurr,
          currencyLabel: platformBackend?.currency_display_name[eachCurr]
        })
      })
    }

    const brand = getFieldValue(signupData, "brand_id")
    const nationality = getFieldValue(signupData, "nationality")
    const platform = getFieldValue(signupData, "platform")
    const sli = constructCurrencySlice(platformCurrenciesObject, brand, slices)

    noNulls.push(...getCurrencies(brand, platform, nationality, countryCode, sli))

    setFinalSlices(noNulls)
  }, [slices, platformBackend])

  const currencyFieldName = useMemo(() => {
    return slices.find((s) => s.variation === FieldVariation.CardGroup)?.primary.fieldName
  }, [slices])

  useEffect(() => {
    if (platformCurrencies.length > 1) {
      const currentSelectedCurrency = getFieldValue(getCurrentSignupData(), currencyFieldName)

      const currentSelectedCurrencyLabel = getFieldValue(getCurrentSignupData(), `${currencyFieldName}_label`)

      updateCurrentStepSignupData({
        currency: platformCurrencies.includes(currentSelectedCurrency) ? currentSelectedCurrency : "",
        currency_label: platformCurrencies.includes(currentSelectedCurrency) ? currentSelectedCurrencyLabel : ""
      })
    }
  }, [])

  useEffect(() => {
    const btn = sessionStorage.getItem("button")
    if (platformCurrencies.length === 1) {
      if (btn === "back") {
        sessionStorage.removeItem("button")
        goToPreviousStep(signupData, prevStep, language)
      }
    }
  }, [])

  const onContinueClick = async () => {
    const currency = getFieldValue(getCurrentSignupData(), "currency")
    const platformCurrenciesSignupInfo = getCurrentSignupData()?.platform_currencies?.platform_currency
    const platformCurrenciesLocalStorage = getLocalStorage(LOCAL_STORAGE_PLATFORM_CURRENCIES)
    const selectedCurrencyData = platformCurrenciesLocalStorage
      ? platformCurrenciesLocalStorage[currency]
      : platformCurrenciesSignupInfo[currency]
    const singleCurrencyAvailable = platformCurrencies[0]
    if (platformCurrencies.length === 1) {
      const currencyName = platformBackend?.currency_display_name[singleCurrencyAvailable]
      continueToNextStep(slices, {
        [uid]: {
          [currencyFieldName]: singleCurrencyAvailable,
          [`${currencyFieldName}_label`]: `${currencyName} (${singleCurrencyAvailable})`,
          other_selected: false,
          mt4usergroup_name: selectedCurrencyData.length > 0 ? selectedCurrencyData[0].mt4usergroup_name : ""
        }
      })
    } else {
      continueToNextStep(slices, {
        [uid]: {
          ...currentStepSignupData,
          mt4usergroup_name: selectedCurrencyData.length > 0 ? selectedCurrencyData[0].mt4usergroup_name : ""
        }
      })
    }
  }

  if (finalSlices && !isFetchingPlatformsBackend) {
    return <SignupStepBase {...props} slices={finalSlices} onContinueClick={onContinueClick} skipUpdateSignupData />
  }
  return <Loading />
}

export default AccountCurrency
