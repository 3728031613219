import { useState, useEffect } from "react"
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3"

export const useReCaptchaWithProvider = (siteKey, action) => {
  const [token, setToken] = useState<string | null>(null)
  const [ready, setReady] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const generateReCaptchaToken = async (executeRecaptcha) => {
    if (!executeRecaptcha) {
      //Execute recaptcha not yet available
      return
    }

    try {
      const token = await executeRecaptcha(action)
      setToken(token)
    } catch (error) {
      console.error("Error executing reCAPTCHA", error)
    }
  }

  useEffect(() => {
    const handleVerifyCaptcha = async () => {
      if (!executeRecaptcha) {
        //Execute recaptcha not yet available
        return
      }
      try {
        const token = await executeRecaptcha(action)
        setToken(token)
        setReady(true)
      } catch (error) {
        console.error("Error executing reCAPTCHA", error)
      }
    }

    handleVerifyCaptcha()
  }, [executeRecaptcha, action])

  const renderReCaptcha = (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      {/* This component is necessary to provide GoogleReCaptchaProvider */}
    </GoogleReCaptchaProvider>
  )

  return { token, ready, renderReCaptcha, generateReCaptchaToken }
}
