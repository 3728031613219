import React, { useEffect, useState } from "react"
import { ActionButton, ActionButtonActionType, BottomArea, Drawer } from "components"
import TopTextInfo from "components/pages/signup/Common/TopTextInfo"
import Fields from "components/pages/signup/Common/Fields"
import { PrismicSignStepType, clearAllSignupData, getActionButtons, getInfoModal, isFinEnvironment } from "utils"
import { useMobileMode, useModal, useSignupData } from "hooks"
import { useRouter } from "next/router"

const DeclinedAccount = (props: PrismicSignStepType["data"]) => {
  const isMobile = useMobileMode()
  const router = useRouter()
  const { updateCurrentStepNumberOfParts } = useSignupData()

  useEffect(() => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("user_info")
    sessionStorage.removeItem("access_token")
    localStorage.setItem("signup_data", JSON.stringify({}))
    updateCurrentStepNumberOfParts(1)
  }, [])
  useEffect(() => {
    localStorage.setItem("lastPageUserWasOn", "declined-account")
    const redirectToHome = () => {
      window.location.replace(`${process.env.NEXT_PUBLIC_HOST_NAME}`)
    }
    history.pushState(null, "", router.asPath)
    history.pushState(null, "", router.asPath)

    const onPopStateHandler = () => {
      redirectToHome()
    }

    window.addEventListener("popstate", onPopStateHandler)

    return () => {
      window.removeEventListener("popstate", onPopStateHandler)
    }
  }, [router])

  const onContinueHandler = () => {
    clearAllSignupData()
    if (isFinEnvironment()) {
      window.location.href = "https://v2.fin.tradenation.com"
    } else {
      window.location.href = process.env.NEXT_PUBLIC_HOST_NAME
    }
  }

  const { textAlign, icon, title, description, slices } = props
  const actionButtons = getActionButtons(slices)
  const exitButton = actionButtons.find((btn) => btn.actionType === ActionButtonActionType.Submit)
  const extraInfoButton = actionButtons.find((btn) => btn.actionType === ActionButtonActionType.ExtraInfo)

  // Bottom Drawer for mobile
  const [drawerOpen, setDrawerOpen] = useState(false)

  // Modal for tablet / desktop
  const modal = getInfoModal(slices)
  const { openModal, displayModal } = useModal()

  const toggleExtraInfo = () => {
    if (isMobile) {
      setDrawerOpen(true)
    } else {
      openModal({
        icon: modal.icon,
        title: modal.title,
        text: modal.description,
        hasCloseIcon: true,
        children: <Fields slices={slices} marginBottom={60} />
      })
    }
  }

  return (
    <>
      <TopTextInfo textAlign={textAlign} icon={icon} title={title} description={description} gap={20} />
      <BottomArea>
        <ActionButton
          displayText={extraInfoButton.displayText}
          onClick={toggleExtraInfo}
          variant="text"
          textUnderline
        />
        <ActionButton
          displayText={exitButton.displayText}
          onClick={onContinueHandler}
          actionType={ActionButtonActionType.Continue}
        />
      </BottomArea>
      {isMobile ? (
        <Drawer open={drawerOpen} toggleDrawer={setDrawerOpen}>
          <TopTextInfo
            textAlign="center"
            icon={modal.icon}
            title={modal.title}
            description={modal.description}
            gap={20}
            margin="0"
          />
          <Fields slices={slices} marginTop={60} marginBottom={60} />
        </Drawer>
      ) : (
        displayModal()
      )}
    </>
  )
}

export default DeclinedAccount
