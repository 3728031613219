import React, { useState, useMemo, useEffect, useContext } from "react"
import { CircularProgress, Container, Snackbar, Typography } from "@mui/material"
import "react-multi-carousel/lib/styles.css"
import {
  ActionButton,
  Field,
  InfoBox,
  SocialButtons,
  WordSeparator,
  AnimatedLogo,
  ActionButtonActionType,
  AllFieldsType,
  InfoBoxType,
  PrismicActionButtonType,
  SliceNameType,
  WidgetProps,
  LanguageSelector,
  RichText
} from "components"

import {
  fieldValidation,
  getFieldsAndInfoBoxes,
  ObjectWithFieldsType,
  SignupStepType,
  setToken,
  getIncompleteSignupInfoAndRedirect,
  getCxdRef,
  setTokenExpiry,
  TranslationType,
  goToPage,
  Sentry,
  isFinEnvironment,
  isStageEnvironment
} from "utils"
import { useAccountData, useDesktopXLMode, useMobileOrTabletMode, useSignupData } from "hooks"
import { DisclaimerContext } from "shared"
import { useCheckCaptchaAndSignupUserMutation, useLoginMutation } from "redux/features/loginapi/loginApi"
import { LoginType } from "redux/types"
import { Context } from "pages/_app"
import {
  CircularProgressComponent,
  DesktopContainer,
  HeaderSection,
  PageWelcomeContent,
  LeftSide,
  RightSide,
  BackgroundGradient,
  BackgroundGradientBig,
  bottomInfoStyle,
  LeftSideTop,
  LeftSideBottom,
  wordSeparatorStyle,
  buttonStyle,
  HeaderSectionContainer,
  Subtitle
} from "./SignupWelcome.styled"

import WidgetSignupWelcome from "components/pages/signup/WidgetSignupWelcome"
import TrustPilot from "./welcome/TrustPilot"
import { LanguageContext } from "context/LanguageContext"
import { RichTextField } from "@prismicio/types"
import { useReCaptchaWithProvider } from "hooks/useCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { SnackbarCloseReason } from "@mui/base"

const SignupWelcome = (
  props: SignupStepType & { widgetprops?: WidgetProps } & {
    setCurrentContent?: React.Dispatch<React.SetStateAction<string>> & {
      translation?: TranslationType
    }
  }
) => {
  const {
    uid,
    bottomInfo,
    slices,
    widgetprops,
    setCurrentContent,
    wordSeparator,
    signUpOrangeText,
    signUpText,
    description
  } = props
  const isMobileOrTablet = useMobileOrTabletMode()
  const isDesktopXL = useDesktopXLMode()
  const { language } = useContext(LanguageContext)
  const { signupData, updateSignupData, continueToNextStep, updatePromptMessage } = useSignupData()
  const isEnglish = language.language_code === "en"

  const { clearAllData } = useAccountData()

  const { validationMessages } = useContext(Context)
  const { height: disclaimerHeight } = useContext(DisclaimerContext)
  const { fieldsAndInfoBoxes, fields } = getFieldsAndInfoBoxes(slices)
  const [signupLoading, setSignupLoading] = useState(false)
  const isWidget = Boolean(widgetprops)
  const cxdRef = getCxdRef()

  const { renderReCaptcha } = useReCaptchaWithProvider(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, "submit")
  const [checkCaptchaAndSignupUser, { isError, error }] = useCheckCaptchaAndSignupUserMutation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [openSnackBar, setOpenSnackBar] = React.useState(false)

  useEffect(() => {
    clearAllData()
  }, [])

  // 'invalidStep' is related with username and password being empty or
  // with an invalid format
  const [invalidStep, setInvalidStep] = useState(true)
  const [data, setData] = useState(
    fields.reduce((acc: ObjectWithFieldsType, field) => {
      return {
        ...acc,
        [field.fieldName]: ""
      }
    }, {})
  )
  // SOCIAL BUTTONS
  const displaySocialButtons = () => {
    return <SocialButtons slices={slices} isSignupWidget={isWidget} />
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackBar(false)
  }

  const getCurrentSignupData = () => ({ ...signupData, [uid]: data })
  const displayFieldsAndInfoBoxes = () => {
    return fieldsAndInfoBoxes.map((fieldData: AllFieldsType | InfoBoxType) => {
      if (fieldData.sliceType === SliceNameType.InfoBox) {
        // Info Box
        return <InfoBox key={JSON.stringify(fieldData)} {...fieldData} />
      }

      // Field
      const handleChange = (newValues: ObjectWithFieldsType) => {
        setData({
          ...data,
          ...newValues
        })
      }

      const handleKeyDown = (e) => {
        const isProd = process.env.NEXT_PUBLIC_ENV === "PROD"
        if (!isProd) {
          if ((e.metaKey || e.ctrlKey) && e.key === "k") {
            setSignupLoading(true)
            if (fieldData.fieldName === "password") {
              const newPassword = "Test1234!"
              const newEmail = `test_${Date.now()}@foobar.com`
              handleChange({ password: newPassword, email: newEmail })
              setTimeout(() => {
                setInvalidStep(false)
              }, 300)
              setTimeout(() => {
                const contBtn = document.getElementById("continue")
                contBtn.click()
              }, 400)
            }
          }
        }
      }

      useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        return () => {
          document.removeEventListener("keydown", handleKeyDown)
        }
      }, [])

      return (
        <Field
          key={fieldData.fieldName}
          {...fieldData}
          required={false}
          currentValue={data[fieldData.fieldName]}
          handleChange={handleChange}
          signupData={getCurrentSignupData()}
          widgetprops={widgetprops}
          isLoginOrSignup
        />
      )
    })
  }

  useEffect(() => {
    const stepIsInvalid = () => {
      let isInvalid = false
      fields.forEach((field) => {
        const { fieldName } = field
        if (fieldValidation(field, data[fieldName], getCurrentSignupData(), validationMessages, true).length > 0) {
          isInvalid = true
        }
      })

      return isInvalid
    }

    setInvalidStep(stepIsInvalid())
  }, [data, fields])

  // SIGNUP BUTTON
  const continueButton: PrismicActionButtonType | undefined = useMemo(
    () =>
      slices.find(
        (slice) =>
          slice.slice_type === SliceNameType.ActionButton &&
          slice.primary.actionType === ActionButtonActionType.Continue
      ),
    [slices]
  )

  const [login, { isLoading }] = useLoginMutation()
  const loginData = async () => {
    return login({
      body: {
        username: data.email,
        password: data.password
      }
    }).unwrap() as LoginType
  }

  const continueToNextOrHandleIncomplete = async (loginUser: LoginType) => {
    setToken(loginUser)
    setTokenExpiry(loginUser)

    let gotRedirected
    try {
      gotRedirected = await getIncompleteSignupInfoAndRedirect(updateSignupData)
    } catch (error) {
      Sentry.captureException(error)
      displaySignupError("", validationMessages.user_exists_rich_text as unknown as RichTextField)
      return
    }

    if (!gotRedirected) {
      Sentry.addBreadcrumb({
        category: "signup",
        message: `${data?.email} started a new signup process`,
        level: "info"
      })
      Sentry.setUser({ email: data?.email })
      const { password, ...dataWithoutPassword } = data
      const addCxdToWelcome = {
        [uid]: {
          ...dataWithoutPassword,
          ...(cxdRef ? { cxdRef: cxdRef } : {}) // Adding cxd to welcome step object
        }
      }
      continueToNextStep(slices, addCxdToWelcome, isWidget)
    }
  }

  const displaySignupError = (errorMessage: string, errorMessageRT?: RichTextField) => {
    setSignupLoading(false)
    updatePromptMessage({
      message: errorMessage,
      success: false,
      richTextMessage: errorMessageRT
    })
    setInvalidStep(true)
  }

  const displayContinueButton = () => {
    if (continueButton) {
      const onClick = async () => {
        if (!invalidStep) {
          try {
            if (executeRecaptcha) {
              setSignupLoading(true)

              // Execute reCAPTCHA
              const skipCaptcha = isFinEnvironment() || isStageEnvironment()
              let token
              if (!skipCaptcha) {
                token = await executeRecaptcha("submit")
              }
              const result = await checkCaptchaAndSignupUser({
                body: { token, user: { email: data.email, password: data.password }, skipCaptcha }
              }).unwrap()

              if (result.human) {
                const loginUser = await loginData()

                if (loginUser.access_token) {
                  continueToNextOrHandleIncomplete(loginUser)
                } else {
                  displaySignupError("", validationMessages.user_exists_rich_text as unknown as RichTextField)
                  return
                }
              }
            } else {
              Sentry.captureException("reCAPTCHA is not ready.")
            }
          } catch (error) {
            if (error?.statusCode === 429) {
              displaySignupError(validationMessages.signup_too_many_requests)
            } else if (error?.statusCode === 400) {
              const loginUser = await loginData()

              if (loginUser.error) {
                displaySignupError(validationMessages[error.code])
              }
            } else {
              setSignupLoading(false)
            }
            Sentry.captureException(error)
          }
        }
      }

      return (
        <>
          <span id="signup" style={{ width: "100%" }}>
            <ActionButton
              id="continue"
              displayText={isWidget ? "Sign up now" : continueButton.primary.displayText || "Continue"}
              sx={{
                ...buttonStyle,
                width: "100%"
              }}
              onClick={onClick}
              disabled={invalidStep}
              actionType={ActionButtonActionType.Signup}
              isSignupOrLogin
            />
          </span>
        </>
      )
    }
    return null
  }

  const displayBottomInfo = () => {
    return (
      <InfoBox
        defaultText={bottomInfo}
        textFontSize="18px"
        sx={bottomInfoStyle}
        textFontWeight="400"
        defaultTextColor={"white"}
        widgetprops={null}
        defaultBackgroundColor={"transparent"}
        isWidget={isWidget}
      />
    )
  }

  if (isWidget) {
    return (
      <WidgetSignupWelcome
        signupLoading={signupLoading}
        isLoading={isLoading}
        displayFieldsAndInfoBoxes={displayFieldsAndInfoBoxes()}
        displayBottomInfo={displayBottomInfo()}
        displayContinueButton={displayContinueButton()}
        displaySocialButtons={displaySocialButtons()}
        disclaimerHeight={disclaimerHeight}
        isDesktopXL={isDesktopXL}
        tn2WidgetData={props.widgetprops?.tn2WidgetData}
        setCurrentContent={setCurrentContent}
      />
    )
  }

  return (
    <>
      <HeaderSection data-testid="signup-welcome-header-section">
        <HeaderSectionContainer maxWidth="xl">
          <AnimatedLogo width={250} height={39} />
          <LanguageSelector />
        </HeaderSectionContainer>
      </HeaderSection>
      <DesktopContainer isMobile={isMobileOrTablet}>
        {isError && error && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            message="Captcha failed"
          />
        )}
        <BackgroundGradient />
        <BackgroundGradientBig />
        <Container maxWidth="xl">
          <PageWelcomeContent isSignupLoading={isLoading || signupLoading} isMobile={isMobileOrTablet}>
            <LeftSide>
              <LeftSideTop isEnglish={isEnglish}>
                <Typography variant="h2" color="white">
                  <span>{signUpOrangeText} </span>
                  {signUpText}
                </Typography>
                <Typography
                  style={{
                    fontSize: "24px"
                  }}
                  variant="body1"
                  color="white"
                >
                  Or,{" "}
                  <Subtitle
                    style={{
                      fontSize: "inherit",
                      fontWeight: "bold"
                    }}
                    onClick={() => {
                      goToPage("/login")
                    }}
                  >
                    log in here
                  </Subtitle>
                </Typography>
                {isMobileOrTablet && (
                  <span id="signuplogin">
                    <RichText field={bottomInfo} />
                  </span>
                )}
              </LeftSideTop>
              <LeftSideBottom>
                {!isMobileOrTablet && (
                  <>
                    <Typography variant="body1">
                      <RichText field={description} />
                    </Typography>
                    <TrustPilot />
                  </>
                )}
              </LeftSideBottom>
            </LeftSide>
            <RightSide>
              {" "}
              {displaySocialButtons()}
              <WordSeparator text={wordSeparator} lineColor={"#fff"} sx={wordSeparatorStyle} />
              {displayFieldsAndInfoBoxes()}
              {displayContinueButton()}
              {renderReCaptcha}
              {!isMobileOrTablet && (
                <span id="signuplogin">
                  <RichText field={bottomInfo} />
                </span>
              )}
              {isMobileOrTablet && (
                <div>
                  <RichText field={description} />
                  <TrustPilot />
                </div>
              )}
            </RightSide>
            {(isLoading || signupLoading) && (
              <CircularProgressComponent>
                <CircularProgress />
              </CircularProgressComponent>
            )}
          </PageWelcomeContent>
        </Container>
      </DesktopContainer>
    </>
  )
}

export default SignupWelcome
