import React, { useState, useEffect, useCallback } from "react"
import * as Sentry from "@sentry/react"
import { BottomArea, Loading, Text, TextfieldInputBoxes } from "components"
import TopTextInfo from "components/pages/signup/Common/TopTextInfo"
import {
  OK,
  clear2faCode,
  getFieldValue,
  SignupStepType,
  getLocalStorage,
  setLocalStorage,
  // postHogFireEvent,
  POSTHOG_CONTINUE_BUTTON_CLICK,
  isProdEnvironment
} from "utils"
import { useMobileMode, useSignupData } from "hooks"
import {
  useSendSmsCodeMutation,
  useUserMetadataMutation,
  useVerify2faMutation
} from "redux/features/accountapi/accountApi"
import { multiTextBottomStyle, multiTextTopStyle, textStyle } from "./PhoneNumberVerification.styled"

const RETRY_BUFFER_S = 30
const LAST_2FA_ATTEMPT = "last_2fa_attempt"

const PhoneNumberVerification = (props: SignupStepType) => {
  const isMobile = useMobileMode()
  const { signupData, continueToNextStep, stepUid } = useSignupData()
  const telephone = signupData["phone-number"].telephone
  const email = getFieldValue(signupData, "email")

  const { icon, title, description, slices, textAlign, prevStep } = props
  const [code, setCode] = useState(clear2faCode())
  const [incorrectCode, setIncorrectCode] = useState(false)
  const [validCode, setValidCode] = useState(false)
  const [send2faCode, { isLoading: send2faCodeLoading }] = useSendSmsCodeMutation()

  const [retryTimeout, setRetryTimeout] = useState(RETRY_BUFFER_S)
  const isRetryTimeoutActive = retryTimeout > 0

  const sliceWithTextElements = slices.find((slice) => slice.slice_type === "phone_verification_texts")
  const { didNotReceiveText, resendCodeInText, tapHereText, clickHereText } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (sliceWithTextElements as any)?.primary || {}

  const onSmsSend = useCallback(async () => {
    setIncorrectCode(false)

    try {
      setLocalStorage(LAST_2FA_ATTEMPT, new Date().getTime().toString())
      send2faCode({ body: { telephone } })
    } catch (error) {
      // modal or logging required here
      Sentry.captureException(error)
    }
  }, [])

  const onSmsResend = () => {
    if (retryTimeout <= 0) {
      onSmsSend()
      setRetryTimeout(RETRY_BUFFER_S)
    }
  }

  useEffect(() => {
    const lastAttempt = getLocalStorage(LAST_2FA_ATTEMPT)
    if (new Date().getTime() - lastAttempt >= RETRY_BUFFER_S * 1000) {
      onSmsSend()
    }

    const retryInterval = setInterval(() => {
      if (isRetryTimeoutActive) {
        setRetryTimeout((prev) => prev - 1)
      }
    }, 1000)

    return () => {
      clearTimeout(retryInterval)
    }
  }, [])

  const [verify2fa, { isLoading: verify2faLoading }] = useVerify2faMutation()
  const [userMetadata] = useUserMetadataMutation()

  const onCodeSubmit = async () => {
    let codeIsValid = false
    const enteredCode = code.join("")
    if (!isProdEnvironment() && enteredCode === "199999") {
      codeIsValid = true
    } else {
      try {
        const body = { code: enteredCode, telephone, email }
        const result = await verify2fa({ body }).unwrap()
        if (result.status === OK) {
          userMetadata({
            body: {
              item_name: "phone_number_verified",
              item_value: result.telephone
            }
          })
        }
        codeIsValid = result.status === OK
      } catch (error) {
        console.log("error is ", error)
        Sentry.captureException(error)
      }
    }

    setValidCode(codeIsValid)

    if (codeIsValid) {
      continueToNextStep(slices)

      // Post Hog
      // postHogFireEvent(POSTHOG_CONTINUE_BUTTON_CLICK, {
      //   currentStep: stepUid,
      //   currentStepPart: 1
      // })
    } else {
      setIncorrectCode(true)
    }
  }

  const updateCode = (newCode: string[]) => {
    setCode(newCode)
    if (incorrectCode) {
      setIncorrectCode(false)
    }
  }

  useEffect(() => {
    if (code.every((elem) => elem)) {
      onCodeSubmit()
    }
  }, [code])

  useEffect(() => {
    if (incorrectCode) {
      setCode(clear2faCode())
    }
  }, [incorrectCode])

  return (
    <>
      <TopTextInfo icon={icon} textAlign={textAlign} title={title} description={description} />
      <TextfieldInputBoxes value={code} handleChange={updateCode} incorrectCode={incorrectCode} />
      <Text
        multiText={[
          { text: didNotReceiveText, sx: multiTextTopStyle },
          {
            text: `${
              isRetryTimeoutActive ? `${resendCodeInText} ${retryTimeout}` : isMobile ? tapHereText : clickHereText
            }`,
            onClick: onSmsResend,
            sx: isRetryTimeoutActive ? multiTextTopStyle : multiTextBottomStyle
          }
        ]}
        sx={textStyle}
      />
      <BottomArea slices={slices} prevStep={prevStep} continueButtonDisabled={!validCode} />
      {(send2faCodeLoading || verify2faLoading) && <Loading />}
    </>
  )
}

export default PhoneNumberVerification
