import React from "react"
import Image from "next/image"
import { CircularProgress, Divider, Grid, Link, Typography } from "@mui/material"
import { RichText, LanguageSelector, TN2WidgetDataType, WordSeparator } from "components"
import {
  CircularProgressComponent,
  DesktopContainer,
  DesktopContent,
  DiagonalLine,
  WidgetLeftSide,
  WidgetRightSide,
  WidgetContainer,
  LogoAreaStyle,
  SignupLeftSideWidgetContainer,
  SignupRightSide
} from "components/pages/signup/WidgetSignupWelcome.styled"
import TradingViewLogoWhite from "components/tradingview/TradingViewLogoWhite.svg"
import { PageWelcomeContent } from "styles"
import { useMobileMode } from "hooks"

type WidgetSignupWelcomeProps = {
  isLoading: boolean
  signupLoading: boolean
  isDesktopXL: boolean
  disclaimerHeight: number
  displaySocialButtons: React.ReactElement
  displayFieldsAndInfoBoxes: JSX.Element[]
  displayContinueButton: React.ReactElement
  displayBottomInfo?: React.ReactElement
  setCurrentContent?: React.Dispatch<React.SetStateAction<string>>
  tn2WidgetData?: TN2WidgetDataType
}

const WidgetSignupWelcome = ({
  isLoading,
  signupLoading,
  isDesktopXL,
  disclaimerHeight,
  displaySocialButtons,
  displayFieldsAndInfoBoxes,
  displayContinueButton,
  setCurrentContent,
  tn2WidgetData
}: WidgetSignupWelcomeProps) => {
  const isMobile = useMobileMode()

  return (
    // Add Context
    <DesktopContainer widget isMobile={isMobile}>
      <DesktopContent widget isMobile={isMobile}>
        <WidgetContainer isMobile={isMobile}>
          <WidgetLeftSide isMobile={isMobile}>
            <Grid item sx={LogoAreaStyle(isDesktopXL, disclaimerHeight, isMobile)}>
              <Image width={225} src={TradingViewLogoWhite} alt="Trading View" />
              <LanguageSelector />
              <SignupLeftSideWidgetContainer isMobile={isMobile}>
                <RichText field={tn2WidgetData?.data.signup_title} sx={{ color: "white" }} />
              </SignupLeftSideWidgetContainer>
              <SignupLeftSideWidgetContainer bottomText>
                <RichText field={tn2WidgetData?.data.signup_description} sx={{ color: "white" }} />
              </SignupLeftSideWidgetContainer>
            </Grid>
          </WidgetLeftSide>
          <DiagonalLine />
          {isMobile && (
            <Divider
              variant="fullWidth"
              sx={{
                borderColor: "rgb(255 253 255 / 12%)",
                width: "100%",
                borderBottomWidth: "2px",
                marginBottom: "20px"
              }}
            />
          )}
          <WidgetRightSide isMobile={isMobile}>
            <SignupRightSide>
              <PageWelcomeContent isSignupLoading={isLoading || signupLoading} isWidget isMobile={isMobile}>
                {(isLoading || signupLoading) && (
                  <CircularProgressComponent>
                    <CircularProgress />
                  </CircularProgressComponent>
                )}
                {displaySocialButtons}
                <WordSeparator text="or" isWidget />
                {displayFieldsAndInfoBoxes}
                {displayContinueButton}
                <Typography
                  onClick={() => setCurrentContent("signin")}
                  sx={{
                    color: "white",
                    ml: 0.8,
                    display: "flex",
                    justifyContent: "center"
                  }}
                  variant={"body2"}
                >
                  {tn2WidgetData?.data.signup_to_login_text}&nbsp;
                  <Link style={{ color: "white" }} href="#">
                    Log in here
                  </Link>
                </Typography>
              </PageWelcomeContent>
            </SignupRightSide>
          </WidgetRightSide>
        </WidgetContainer>
      </DesktopContent>
    </DesktopContainer>
  )
}

export default WidgetSignupWelcome
