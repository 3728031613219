import React from "react"
import { Loading } from "components"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import { SignupStepType } from "utils"
import { useScoreCheck } from "hooks"

const AssetWorth = (props: SignupStepType) => {
  const { scoreCheckValidation, isLoading } = useScoreCheck()

  const { slices, uid } = props

  const onContinueClick = () => {
    scoreCheckValidation(slices, uid)
  }

  return (
    <>
      <SignupStepBase {...props} onContinueClick={onContinueClick} />
      {isLoading && <Loading />}
    </>
  )
}

export default AssetWorth
