import { greyColor } from "styles"

export const multiTextTopStyle = {
  color: greyColor
}

export const multiTextBottomStyle = {
  cursor: "pointer",
  textDecoration: "underline"
}

export const textStyle = {
  fontWeight: 300
}
