import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import speakeasy from "speakeasy"
import { RichText, ActionButton, Icon, Loading, TextfieldInputBoxes, ActionButtonActionType } from "components"
import { PageContainer, PageContentCenterAligned } from "styles"
import { LOCAL_STORAGE_2FA, OK, clear2faCode, getLocalStorage, goToPage, PrismicSignStepType } from "utils"
import { useVerify2faMutation } from "redux/features/accountapi/accountApi"
import { actionButtonStyle } from "./TwoFactorCode.styled"

const TwoFactorCode = (props: PrismicSignStepType["data"]) => {
  const { icon, title } = props

  const [code, setCode] = useState(clear2faCode())
  const [verify2fa, { isLoading: verify2faLoading }] = useVerify2faMutation()
  const onCodeSubmit = async () => {
    const secret2fa = getLocalStorage(LOCAL_STORAGE_2FA)
    let codeIsValid = false
    if (secret2fa.hex) {
      codeIsValid = speakeasy.totp.verify({
        secret: secret2fa.hex,
        encoding: "hex",
        token: code.join(""),
        window: 1
      })
    } else {
      try {
        const body = { code: code.join("") }
        const result = await verify2fa({ body }).unwrap()
        codeIsValid = result.status === OK
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    goToPage(`/signup/two-factor-${codeIsValid ? "success" : "fail"}`)
  }

  return (
    <PageContainer>
      <PageContentCenterAligned>
        <Icon icon={icon} />
        <RichText field={title} />
        <TextfieldInputBoxes value={code} handleChange={setCode} />
        <ActionButton
          displayText="Submit"
          onClick={onCodeSubmit}
          disabled={!code.every((elem) => elem)}
          sx={actionButtonStyle}
          actionType={ActionButtonActionType.Continue}
        />
        {verify2faLoading && <Loading />}
      </PageContentCenterAligned>
    </PageContainer>
  )
}

export default TwoFactorCode
