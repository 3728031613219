import React from "react"
import { RichText, ActionButton, Icon, ActionButtonActionType } from "components"
import { PageContainer, PageContentCenterAligned, Row } from "styles"
import { goToPage, PrismicSignStepType } from "utils"

const TwoFactorFail = (props: PrismicSignStepType["data"]) => {
  const { icon, title } = props

  return (
    <PageContainer>
      <PageContentCenterAligned>
        <Icon icon={icon} />
        <RichText field={title} />
        <Row>
          <ActionButton displayText="Try Again?" onClick={() => goToPage("/signup/welcome")} />
          <ActionButton displayText="Home" onClick={() => goToPage("/")} actionType={ActionButtonActionType.Continue} />
        </Row>
      </PageContentCenterAligned>
    </PageContainer>
  )
}

export default TwoFactorFail
