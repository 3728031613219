import React from "react"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import { SignupStepType, compareValidFieldsAndValues, getFieldValue, getHiddenFields, getInfoModal } from "utils"
import { useModal, useSignupData } from "hooks"

const getTopBandValue = (value: string) => {
  if (!value) {
    return 0
  }
  return Number(value.split(" ").pop().substring(1).replace(",", ""))
}

const FirstDeposit = (props: SignupStepType) => {
  const { getCurrentSignupData, continueToNextStep } = useSignupData()

  const { slices } = props

  const scoreThresholds = getHiddenFields(slices)[0]

  const modal = getInfoModal(slices)
  const { openModal, displayModal, closeModal } = useModal()

  const onContinueClick = async () => {
    const signupData = getCurrentSignupData()

    // Check if modal is displayable or not
    const { showConditionalField, showConditionalValue } = modal
    const modalIsDisplayable = compareValidFieldsAndValues(showConditionalField, showConditionalValue, signupData)

    let showModal = false

    if (modalIsDisplayable) {
      const income = getTopBandValue(getFieldValue(signupData, "annual_income_label"))
      const savings = getTopBandValue(getFieldValue(signupData, "asset_worth_label"))
      const deposit = getTopBandValue(getFieldValue(signupData, "first_deposit_label"))

      let finalScoreThreshold = scoreThresholds.defaultValue
      scoreThresholds.options.forEach((option) => {
        if (compareValidFieldsAndValues(option.conditionalField, option.conditionalValue, signupData)) {
          finalScoreThreshold = option.value
        }
      })

      const thresholdPercentage = Number(finalScoreThreshold) * 0.01

      showModal = (income + savings) * thresholdPercentage < deposit
    }

    if (showModal) {
      openModal({
        icon: modal.icon,
        title: modal.title,
        text: modal.description,
        buttons: [
          {
            text: modal.secondButtonText || "Change deposit",
            onClick: closeModal,
            lightBackground: true
          },
          {
            text: modal.buttonText || "Yes, I understand",
            onClick: () => continueToNextStep(slices)
          }
        ]
      })
    } else {
      continueToNextStep(slices)
    }
  }

  return (
    <>
      <SignupStepBase {...props} onContinueClick={onContinueClick} />
      {displayModal()}
    </>
  )
}

export default FirstDeposit
