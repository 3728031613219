import styled from "@emotion/styled"

export const SumSubContainer = styled.div`
  h1 {
    font-size: 40px;
    font-weight: 400;
  }
`

export const boxStyle = {
  mb: 2,
  maxWidth: "600px"
}

export const typographyStyle = {
  ml: 2
}

export const manualBoxStyle = {
  textAlign: "center"
}
