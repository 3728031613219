import React, { useEffect } from "react"
import { Loading } from "components"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import { SignupStepType } from "utils"
import { useScoreCheck, useSignupData } from "hooks"

type Props = SignupStepType & {
  isExperienceQuestions?: boolean
}

const ExperienceAndKnowledgeQuestions = (props: Props) => {
  const { signupData, continueToNextStep, updateSignupData, currentStepSignupData } = useSignupData()
  const { scoreCheckValidation, isLoading } = useScoreCheck()
  const { slices, uid } = props
  const isCardGroup = uid === "experience-questions-au-2"

  const onContinueClick = () => {
    const updatedExperienceData = {
      ...signupData["experience-questions"],
      ...currentStepSignupData
    }
    updateSignupData({
      ...signupData,
      ["experience-questions"]: updatedExperienceData
    })

    if (isCardGroup) {
      scoreCheckValidation(slices, "experience-questions")
    } else {
      continueToNextStep(slices)
    }
  }

  return (
    <>
      <SignupStepBase {...props} slices={slices} onContinueClick={onContinueClick} skipUpdateSignupData />
      {isLoading && <Loading />}
    </>
  )
}

export default ExperienceAndKnowledgeQuestions
