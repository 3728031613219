import React, { useState, useEffect, Fragment, useMemo, useContext } from "react"
import * as prismicH from "@prismicio/helpers"
import {
  Loading,
  Carousel,
  PlatformInfoType,
  RichText,
  TextWithImages,
  ActionButton,
  ActionButtonActionType
} from "components"
import {
  ButtonsContainer,
  changeButtonStyle,
  ContentContainer,
  continueButtonStyle,
  TitleContainer
} from "components/pages/signup/PlatformSelection/PlatformSelected.styled"
import PlatformBanner from "components/pages/signup/PlatformSelection/PlatformBanner"
import {
  SignupStepType,
  getFieldValue,
  getPlatformsInfo,
  getCxdRef,
  LOCAL_STORAGE_PLATFORM_CURRENCIES,
  setLocalStorage,
  getPlatformsWithAffiliates,
  getInitialSelectedPlatform,
  getBackground,
  PlatformBackendType,
  LOCAL_STORAGE_PLATFORM_BACKEND,
  PlatformBackendDataType,
  GeneralApiFieldType,
  hexToRgb
} from "utils"
import { useMobileOrTabletMode, useSignupData } from "hooks"
import { LanguageContext } from "context"
import { useGetAffiliateMt4UsergroupsQuery, useGetBackendByCountryQuery } from "redux/features/signupapi/signupApi"
import { PageContainer } from "styles"
import PlatformSelected from "./PlatformSelected"
import PlatformsList from "./PlatformsList"
import { CarouselContainer, CarouselTitle } from "./PlatformSelection.styled"

enum Steps {
  SelectedPlatform,
  ListOfPlatforms
}

const getPrismicPlatformsWithAffiliates = (
  platforms: PlatformInfoType[],
  platformsWithAffiliates: PlatformBackendDataType[]
) => {
  if (platformsWithAffiliates.length > 0) {
    return platforms.reduce((acc: PlatformInfoType[], platform) => {
      const plat = platformsWithAffiliates.find(
        (p) =>
          p.platform_type.toLowerCase() === platform.platformType.toLowerCase() &&
          p.derivative_type.toLowerCase() === platform.derivativeType.toLowerCase()
      )

      if (plat) {
        acc.push(platform)
      }

      return acc
    }, [])
  }

  return platforms
}

const getPlatformBackend = (platformBackend: PlatformBackendType, selectedPlatform: PlatformInfoType) => {
  return platformBackend?.backends?.find(
    (p) =>
      p.platform_type.toLowerCase() === selectedPlatform.platformType.toLowerCase() &&
      p.derivative_type.toLowerCase() === selectedPlatform.derivativeType.toLowerCase()
  )
}

const getMT4AffiliateCurrencies = (affiliateMt4UserGroups: GeneralApiFieldType[]) => {
  return affiliateMt4UserGroups.reduce((acc, val) => {
    return {
      ...acc,
      [val.currency]: [val]
    }
  }, {})
}

const PlatformSelection = (props: SignupStepType) => {
  const { language } = useContext(LanguageContext)
  const isMobileOrTablet = useMobileOrTabletMode()
  const { continueToNextStep, stepUid } = useSignupData()

  const { slices, title, description } = props
  const { signupData } = useSignupData()

  // Affiliates
  const cxdRef = getCxdRef() || getFieldValue(signupData, "cxd")
  const brand = getFieldValue(signupData, "brand_id")
  const countryCode = getFieldValue(signupData, "country_of_residence")
  const { data: affiliateMt4UserGroups, isFetching: isFetchingAffiliateMt4UserGroups } =
    useGetAffiliateMt4UsergroupsQuery({ affiliate_id: cxdRef, brand }, { skip: !cxdRef })

  const [platforms, setPlatforms] = useState<PlatformInfoType[]>([])
  const { data: platformBackend = {} as PlatformBackendType, isFetching: isFetchingPlatformsInfo } =
    useGetBackendByCountryQuery({ countryCode, affiliate_id: cxdRef })

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_PLATFORM_BACKEND, platformBackend)
    sessionStorage.removeItem("button")
  }, [platformBackend])

  const [selectedPlatform, setSelectedPlatform] = useState<PlatformInfoType>()
  useEffect(() => {
    if (platforms.length > 0) {
      setSelectedPlatform(getInitialSelectedPlatform(platforms, signupData).platform)
    }
  }, [platforms])

  useEffect(() => {
    if ((platformBackend?.backends && selectedPlatform) || affiliateMt4UserGroups?.length > 0) {
      setLocalStorage(
        LOCAL_STORAGE_PLATFORM_CURRENCIES,
        affiliateMt4UserGroups?.length > 0
          ? getMT4AffiliateCurrencies(affiliateMt4UserGroups)
          : getPlatformBackend(platformBackend, selectedPlatform).currency
      )
    }
  }, [platformBackend, selectedPlatform, affiliateMt4UserGroups])

  const platformsWithAffiliates = useMemo(() => getPlatformsWithAffiliates(platformBackend), [platformBackend])

  const [currentSlideIndex, setCurrentSlideIndex] = useState(getInitialSelectedPlatform(platforms, signupData).index)

  useEffect(() => {
    if (platforms.length > 0) {
      setCurrentSlideIndex(getInitialSelectedPlatform(platforms, signupData).index)
    }
  }, [platforms])

  useEffect(() => {
    setSelectedPlatform(platforms[currentSlideIndex])
  }, [currentSlideIndex])

  useEffect(() => {
    if (platformBackend && platformBackend.backends?.length > 0) {
      setPlatforms(
        getPlatformsInfo(
          slices,
          cxdRef,
          brand,
          signupData,
          platformBackend.backends,
          affiliateMt4UserGroups?.length > 0
        )
      )
    }
  }, [language, brand, slices, platformBackend, affiliateMt4UserGroups])

  useEffect(() => {
    setSelectedPlatform((prev) => platforms.find((platform) => platform.backend_id === prev.backend_id))
  }, [platforms])

  const [currentStep, setCurrentStep] = useState(Steps.SelectedPlatform)

  useEffect(() => {
    if (platformsWithAffiliates.length > 0) {
      setSelectedPlatform(
        platforms.find(
          (platform) =>
            platform.platformType.toLowerCase() === platformsWithAffiliates[0].platform_type.toLowerCase() &&
            platform.derivativeType.toLowerCase() === platformsWithAffiliates[0].derivative_type.toLowerCase()
        )
      )
    }
  }, [platformsWithAffiliates])

  const selectPlatform = (platform: PlatformInfoType) => {
    setCurrentStep(Steps.SelectedPlatform)
    setSelectedPlatform(platform)
  }

  const unselectPlatform = () => {
    setCurrentStep(Steps.ListOfPlatforms)
    setSelectedPlatform(null)
  }

  const buttonText = slices.find((slice) => slice.slice_type === "action_button")?.primary.displayText

  if (isFetchingPlatformsInfo || isFetchingAffiliateMt4UserGroups || platforms.length === 0) {
    return <Loading />
  }
  const onContinueHandler = (fieldName, value, backendId) => {
    continueToNextStep(slices, {
      [stepUid]: { [fieldName]: value, type: "live", backend_id: backendId },
      platform_currencies: {
        platform_currency: getPlatformBackend(platformBackend, selectedPlatform).currency
      }
    })
  }
  const displayButtons = (backgroundBottomColor, changeText, fieldName, value, backendId) => {
    return (
      <ButtonsContainer
        backgroundTopColor={hexToRgb(backgroundBottomColor)}
        backgroundBottomColor={backgroundBottomColor}
        isMobileOrTablet={isMobileOrTablet}
      >
        {platforms.length > 1 && isMobileOrTablet && (
          <ActionButton displayText={changeText || "Change"} onClick={unselectPlatform} sx={changeButtonStyle} />
        )}
        <ActionButton
          displayText={buttonText || "Continue"}
          onClick={() => onContinueHandler(fieldName, value, backendId)}
          sx={continueButtonStyle}
          actionType={ActionButtonActionType.Continue}
        />
      </ButtonsContainer>
    )
  }

  switch (currentStep) {
    case Steps.SelectedPlatform:
      if ((platforms.length === 1 || isMobileOrTablet || platformsWithAffiliates.length === 1) && selectedPlatform) {
        return (
          <PlatformSelected
            selectedPlatform={selectedPlatform}
            platforms={getPrismicPlatformsWithAffiliates(platforms, platformsWithAffiliates)}
            getBackground={getBackground}
            unselectPlatform={unselectPlatform}
            slices={slices}
            buttonText={buttonText}
            currency={getPlatformBackend(platformBackend, selectedPlatform).currency}
          />
        )
      }

      return (
        <>
          <CarouselTitle>{prismicH.asText(props.desktopTitle)}</CarouselTitle>
          <CarouselContainer>
            {/*disable left and right arrows if affiliate is present*/}
            <Carousel
              darkButtons
              setCurrentSlideIndex={setCurrentSlideIndex}
              initialSelectedPlatformIndex={platforms.findIndex((p) => p.backend_id === selectedPlatform?.backend_id)}
            >
              {platforms.map((platform) => (
                <Fragment key={platform.value}>
                  <PageContainer
                    padding="0"
                    margin={isMobileOrTablet ? "auto" : "30px auto"}
                    textColor={platform.textColor}
                    maxWidth={isMobileOrTablet ? "100%" : "600px"}
                  >
                    <PlatformBanner
                      priority={true}
                      image={{
                        url: isMobileOrTablet ? platform.image.mobile.url : platform.image.desktop.url,
                        dimensions: isMobileOrTablet
                          ? platform.image.mobile.dimensions
                          : platform.image.desktop.dimensions
                      }}
                      alt="Platform"
                      borderTopLeftRadius={isMobileOrTablet ? "0" : "20px"}
                      borderTopRightRadius={isMobileOrTablet ? "0" : "20px"}
                    />
                    <ContentContainer
                      background={getBackground(platform.backgroundTopColor, platform.backgroundBottomColor, 200)}
                      isMobileOrTablet={isMobileOrTablet}
                      textColor={platform.textColor}
                    >
                      <TitleContainer>
                        <RichText field={platform.title} />
                      </TitleContainer>
                      <TextWithImages description={platform.description} useTradingViewLogo />
                      {!isMobileOrTablet &&
                        displayButtons(
                          platform.backgroundBottomColor,
                          platform.changeText,
                          platform.fieldName,
                          platform.value,
                          platform.backend_id
                        )}
                    </ContentContainer>
                    {isMobileOrTablet &&
                      displayButtons(
                        platform.backgroundBottomColor,
                        platform.changeText,
                        platform.fieldName,
                        platform.value,
                        platform.backend_id
                      )}
                  </PageContainer>
                </Fragment>
              ))}
            </Carousel>
          </CarouselContainer>
        </>
      )
    case Steps.ListOfPlatforms:
      return (
        <PlatformsList
          platforms={platforms}
          title={title}
          description={description}
          getBackground={getBackground}
          selectPlatform={selectPlatform}
        />
      )
    default:
      return null
  }
}

export default PlatformSelection
