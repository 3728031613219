import React, { useContext, useEffect, useState } from "react"
import SumsubWebSdk from "@sumsub/websdk-react"
import moment from "moment"
import { Box, ButtonGroup, Typography } from "@mui/material"
import Image from "next/image"
import localFont from "next/font/local"
import { Loading, ActionButton, ActionButtonActionType } from "components"
import {
  getFieldValue,
  goToPage,
  getActionButtons,
  SignupStepType,
  getIncompleteSignUpInfo,
  getRichText,
  isDevOrFinEnvironment,
  isStageEnvironment
} from "utils"
import { useModal, useSignupData } from "hooks"
import { Row } from "styles"
import { LanguageContext } from "context"
import { useSumSubMutation, useSumSubTokenRequestMutation } from "redux/features/sumsub/sumSubApi"
import { SumSubContainer, boxStyle, manualBoxStyle, typographyStyle } from "./SumSubVerification.styled"
import { Elements } from "prismic-reactjs"

type SignupDataProps = {
  brand_id: string
}

const sumSubOptions = {
  addViewportTag: true,
  adaptIframeHeight: true
}

const montserratFont = localFont({
  src: [
    {
      path: "../../../../pages/fonts/Montserrat-Medium.ttf",
      weight: "500",
      style: "normal"
    }
  ]
})

const SumSubVerification = (props: SignupStepType) => {
  const { slices, uid } = props
  const { language } = useContext(LanguageContext)
  const actionButtons = getActionButtons(slices)
  const [sumSubToken, setSumSubToken] = useState("")
  const [sumSubMessage, setSumSubMessage] = useState(null)
  const [showManualButton, setShowManualButton] = useState(false)
  const [documentCreateDate, setDocumentCreateDate] = useState("")
  const [sumSubLoaded, setSumSubLoaded] = useState(false)
  const [showFirstMessage, setShowFirstMessage] = useState(false)
  const [showSecondMessage, setShowSecondMessage] = useState(false)
  const sumSubConfig = {
    lang: language.language_code,
    uiConf: {
      scrollIntoView: false
    }
  }
  const { openModal, displayModal } = useModal()

  const forceShowManualButton = isDevOrFinEnvironment()
  const isStageEnv = isStageEnvironment()

  const { signupData, updateSignupData, continueToNextStep, currentStepSignupData } = useSignupData()
  const [sumSubCheck, { isLoading }] = useSumSubTokenRequestMutation()
  const [sumSub] = useSumSubMutation()
  const [applicantId, setApplicantId] = useState(null)

  const sumsubVerificationSlice = slices.find((slice) => slice.slice_type === "sumsub_verification")
  // const {bearWithUsMessage, longerThanExpectedMessage,enterInformationManually}

  const brand = getFieldValue(signupData, "brand_id") as unknown as string
  const email = getFieldValue(signupData, "email")
  const [requestedData, setRequestedData] = useState<{
    brand: string
    email: string
  }>({
    brand: brand,
    email: email
  })
  const countryIso3Code = getFieldValue(signupData, "country_iso3_code") as unknown as SignupDataProps

  const populateRequestDataIfEmpty = async () => {
    if (!brand || !email) {
      await getIncompleteSignUpInfo().then(
        (res: { welcome: { email: string }; "country-of-residence": { brand_id: string } }) => {
          if (res["country-of-residence"] && res.welcome) {
            setRequestedData({
              brand: res["country-of-residence"].brand_id as string,
              email: res.welcome.email as string
            })
          }
        }
      )
    }
  }

  useEffect(() => {
    populateRequestDataIfEmpty()
  }, [brand, email])

  const openErrorModal = () => {
    openModal({
      title: getRichText([
        {
          text: "FAIL",
          type: Elements.heading2
        }
      ]),
      text: getRichText([
        {
          text: "Something went wrong. Please try again later.",
          type: Elements.paragraph
        }
      ])
    })
  }

  const callSumSubTokenRequest = async (data) => {
    try {
      const result = await sumSubCheck(data).unwrap()
      setSumSubToken(result.token)
    } catch (err) {
      console.error("Error:", err)
      setSumSubLoaded(true)
      openErrorModal()
    }
  }

  useEffect(() => {
    callSumSubTokenRequest(requestedData)
      .then((r) => {
        console.log("Sumsub response ", r)
      })
      .catch((error) => {
        console.log("Sumsub error: ", error)
      })
  }, [requestedData])

  const accessTokenExpirationHandler = async () => {
    try {
      const result = await sumSubCheck(requestedData).unwrap()
      setSumSubToken(result.token)
      return result.token
    } catch (err) {
      console.error("Error:", err)
    }
  }
  const messageHandler = (type, payload) => {
    if (payload && payload.applicantId) {
      updateSignupData({
        ...signupData,
        [uid]: {
          ...currentStepSignupData,
          applicant_id: payload.applicantId
        }
      })
      setApplicantId(payload.applicantId)
      setSumSubLoaded(true)
    }
    if (isStageEnv) {
      if (payload.answer === "IGNORED") {
        setSumSubMessage("RED")
      }
    }
    if (payload.createDate) {
      setDocumentCreateDate(payload.createDate)
    }

    if (payload && payload.reviewStatus && payload.reviewStatus === "onHold") {
      setSumSubMessage("RED")
    }
    if (payload && payload.reviewResult) {
      switch (payload.reviewResult.reviewAnswer) {
        case "RED":
          setSumSubMessage("RED")
          break
        case "GREEN":
          setSumSubMessage("GREEN")
          break
        default:
      }
    }
  }

  useEffect(() => {
    const targetDate = new Date(documentCreateDate)
    const currentTime = new Date()

    const timeRemainingFirstMessage = targetDate.getTime() + 60000 - currentTime.getTime()

    const timeRemainingSecondMessage = targetDate.getTime() + 120000 - currentTime.getTime()

    // Check if the first message time has passed and show the first message after a minute
    if (timeRemainingFirstMessage > 0) {
      const timeoutId1 = setTimeout(() => {
        setShowFirstMessage(true)
      }, timeRemainingFirstMessage)

      // Check if the second message time has passed and show the second message after two minutes
      if (timeRemainingSecondMessage > 0) {
        const timeoutId2 = setTimeout(() => {
          setShowFirstMessage(false)
          setShowSecondMessage(true)
          setShowManualButton(true)
        }, timeRemainingSecondMessage)

        return () => {
          clearTimeout(timeoutId1)
          clearTimeout(timeoutId2)
        }
      }

      return () => clearTimeout(timeoutId1)
    }
  }, [documentCreateDate])

  useEffect(() => {
    if (sumSubMessage === "GREEN") {
      sumSub(requestedData).then(
        (subData: {
          data: {
            info: {
              idDocs: {
                firstName: string
                lastName: string
                dob: string
                address: {
                  town: string
                  street: string
                  subStreet: string
                  postCode: string
                }
              }[]
              country: SignupDataProps
            }
          }
        }) => {
          if (subData) {
            subData?.data?.info.idDocs.map((doc) => {
              let countriesDoNotMatch
              if (subData?.data?.info.country !== countryIso3Code) {
                countriesDoNotMatch = true
              }
              const extraData = {
                [uid]: {
                  first_name: doc.firstName,
                  last_name: doc.lastName,
                  date_of_birth: moment(doc.dob).format("DD/MM/YYYY") || "none",
                  applicant_id: applicantId,
                  full_address: doc.address || "none",
                  addr_city: doc.address?.town || null,
                  addr_line_1: doc.address?.street || null,
                  addr_line_2: doc.address?.subStreet || null,
                  addr_zip: doc.address?.postCode || null,
                  countriesDoNotMatch: countriesDoNotMatch || null
                }
              }
              continueToNextStep(slices, extraData)
            })
          }
        }
      )
    }
  }, [sumSubMessage])

  const enterInfoManually = () => {
    const extraData = {
      [uid]: {
        applicant_id: applicantId,
        enter_info_manually: "true"
      }
    }
    continueToNextStep(slices, extraData)
  }

  return (
    <>
      {displayModal()}
      {sumSubToken && !isLoading && (
        <SumSubContainer data-testid="sumsub-container">
          {isLoading && !sumSubToken && <Loading />}
          <>
            <SumsubWebSdk
              accessToken={sumSubToken}
              expirationHandler={accessTokenExpirationHandler}
              onMessage={messageHandler}
              config={sumSubConfig}
              options={sumSubOptions}
            />
            <Box display="flex" alignItems="center" sx={boxStyle}>
              {(showFirstMessage || showSecondMessage) && (
                <Image
                  src={
                    "https://images.prismic.io/tnfev2/44587b9b-c476-4a96-a39e-788e0f04f5e5_HeaderLogoAnimated.gif?auto=compress,format"
                  }
                  width={30}
                  height={27}
                  unoptimized // reload gif
                  alt="TradeNation"
                />
              )}
              {showFirstMessage && (
                <Typography sx={typographyStyle} className={montserratFont.className}>
                  {sumsubVerificationSlice.primary.bearWithUsMessage}
                </Typography>
              )}
              {showSecondMessage && (
                <Typography sx={typographyStyle} className={montserratFont.className}>
                  {sumsubVerificationSlice.primary.longerThanExpectedMessage}
                </Typography>
              )}
            </Box>
            <Box sx={manualBoxStyle}>
              <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                <Row justifyContent="space-between">
                  {(showManualButton || sumSubMessage === "RED") && (
                    <ActionButton
                      displayText={sumsubVerificationSlice.primary.enterInformationManually}
                      onClick={enterInfoManually}
                      actionType={ActionButtonActionType.Continue}
                    />
                  )}
                  {forceShowManualButton && (
                    <ActionButton
                      displayText={sumsubVerificationSlice.primary.enterInformationManually}
                      onClick={enterInfoManually}
                      actionType={ActionButtonActionType.Continue}
                    />
                  )}
                  {sumSubMessage === "PASS" && (
                    <ActionButton
                      displayText={actionButtons[0]?.displayText || "Continue"}
                      onClick={() => goToPage(`/signup/${actionButtons[0].defaultRedirect}`)}
                      actionType={ActionButtonActionType.Continue}
                    />
                  )}
                </Row>
              </ButtonGroup>
            </Box>
          </>
        </SumSubContainer>
      )}
      {!sumSubLoaded && <Loading />}
    </>
  )
}

export default SumSubVerification
