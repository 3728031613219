import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { darkBlueColor } from "styles"

export const DesktopContainer = styled(Box)<{
  widget?: boolean
  isMobile?: boolean
}>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: ${darkBlueColor};
  padding: ${(props) => (props.isMobile ? "20px 10px" : " 50px 40px")};
  justify-content: center;
  background: ${(props) => props.widget && "linear-gradient(45deg, #09111c 75%, #214866 100%)"};
`

export const DesktopContent = styled(Box)<{
  widget?: boolean
  isMobile?: boolean
}>`
  width: ${(props) => (props.widget ? "1280px" : "100%")};
  border: ${(props) => props.widget && "1px solid #fff"};
  border-radius: ${(props) => (props.widget && props.isMobile ? "20px" : "20px")};
`

export const LogoAreaStyle = (useDesktopXLMode: boolean, disclaimerHeight: number, isMobile?: boolean) => ({
  top: useDesktopXLMode ? 6 : 10 + disclaimerHeight,
  pb: 2,
  marginBottom: "20px",
  width: isMobile ? "auto" : "602px",
  flex: 1,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media (max-width: 1000px)": {
    width: "auto"
  }
})

export const CircularProgressComponent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  filter: blur(0);
  z-index: 101;
`

export const SignupLeftSideWidgetContainer = styled.div<{
  bottomText?: boolean
  isMobile?: boolean
}>`
  width: ${(props) => !props.isMobile && "600px"};
  flex: 2;
  display: ${(props) => props.bottomText && "contents"};
  word-break: break-all;
  margin-top: 30px;
`

export const WidgetLeftSide = styled.div<{
  isMobile?: boolean
}>`
  position: relative;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  flex: initial;
  padding: ${(props) => (props.isMobile ? "15px" : "50px")};

  @media (max-width: 1000px) {
    width: ${(props) => !props.isMobile && "inherit"};
  }
`

export const WidgetRightSide = styled.div<{ isMobile?: boolean }>`
  position: relative;
  width: ${(props) => (props.isMobile ? "92%" : "calc(50% - 33px)")};
  display: ${(props) => !props.isMobile && "flex"};
  flex: ${(props) => !props.isMobile && "auto"};
  padding: ${(props) => !props.isMobile && "50px"};
  justify-content: ${(props) => !props.isMobile && "end"};
  margin-right: ${(props) => !props.isMobile && "-80px"};

  @media (max-width: 1400px) {
    width: ${(props) => !props.isMobile && "inherit"};
    margin-right: ${(props) => !props.isMobile && "0"};
    justify-content: ${(props) => !props.isMobile && "center"};
  }
`

export const WidgetContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: ${(props) => !props.isMobile && "100%"};

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export const DiagonalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 120%;
  background-color: #5f6b71;
  transform-origin: bottom left;
  transform: translateY(-10%) rotate(25deg);
  left: 40%;
  z-index: 1;

  @media (min-width: 768px) {
    left: calc(40% - 1px);
  }

  @media (max-width: 767px) {
    transform: translateY(-10%) rotate(12deg);
    left: calc(50% - 1px);
  }

  @media (max-width: 1630px) {
    transform: translateY(-10%) rotate(12deg);
    left: calc(45% - 1px);
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

export const SignupRightSide = styled.div``
