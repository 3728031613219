import React, { useContext } from "react"
import { Checkbox, CheckboxType, FieldVariation } from "components"
import { fieldValidation, ObjectWithFieldsType } from "utils"
import { useSignupData, useFieldErrorMessage } from "hooks"
import { Context } from "pages/_app"
import { Container } from "./TermsAndConditionsCheckbox.styled"

type Props = {
  checkbox: CheckboxType
  onCheckboxTextClick: (value: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
}

const TermsAndConditionsCheckbox = ({ checkbox, onCheckboxTextClick, props }: Props) => {
  const { currentStepSignupData, updateCurrentStepSignupData, signupData } = useSignupData()
  const { validationMessages } = useContext(Context)

  const { setErrorMessage, displayErrorMessage } = useFieldErrorMessage()

  const handleChange = (newValues: ObjectWithFieldsType) => {
    updateCurrentStepSignupData({
      ...currentStepSignupData,
      ...newValues
    })
    const { required, fieldName } = checkbox
    const extraValidation = []
    setErrorMessage(
      fieldValidation(props, newValues[fieldName], signupData, validationMessages, required, extraValidation)
    )
  }

  return (
    <Container>
      <Checkbox
        label={checkbox.label}
        value={checkbox.value}
        currentValue={currentStepSignupData[checkbox.fieldName] || ""}
        required={checkbox.required}
        handleChange={handleChange}
        fieldName={checkbox.fieldName}
        content={checkbox.content}
        controlledFieldsDisplay={[]}
        variation={FieldVariation.Checkbox}
        onTextClick={onCheckboxTextClick}
        hasBoxContainer
      />
      {displayErrorMessage()}
    </Container>
  )
}

export default TermsAndConditionsCheckbox
