/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from "react"
import Router from "next/router"
import { Content } from "@prismicio/client"
import { Loading, WidgetProps } from "components"
import DeclinedAccount from "components/pages/signup/DeclinedAccount"
import SumSubVerification from "components/pages/signup/SumSubVerification/SumSubVerification"
import TermsAndConditions from "components/pages/signup/TermsAndConditions"
import ExperienceAndKnowledgeQuestions from "components/pages/signup/ExperienceAndKnowledgeQuestions"
import AssetWorth from "components/pages/signup/AssetWorth"
import FirstDeposit from "components/pages/signup/FirstDeposit"
import PhoneNumberVerification from "components/pages/signup/PhoneNumberVerification"
import PlatformSelection from "components/pages/signup/PlatformSelection/PlatformSelection"
import PlatformMT4Password from "components/pages/signup/PlatformSelection/PlatformMT4Password"
import AccountCurrency from "components/pages/signup/AccountCurrency"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import SignupWelcome from "components/pages/signup/SignupWelcome"
import TwoFactorCode from "components/pages/signup/TwoFactorCode"
import TwoFactorFail from "components/pages/signup/TwoFactorFail"
import TwoFactorSuccess from "components/pages/signup/TwoFactorSuccess"
import SourceQuestion from "components/pages/signup/SourceQuestion"
import {
  goToPage,
  PrismicSignStepType,
  updateLastVisitedStep,
  getLocalStorage,
  checkIfUserIsValid,
  getCxdRef,
  setLocalStorage,
  userExistsRedirect,
  getPreviousStepsAndSections,
  TranslationType,
  getUTMParameters,
  getUTMParametersCache,
  getLastPageUserWasOn,
  saveUTMParametersToCache,
  getCookie,
  getFieldValue,
  fetchUser
} from "utils"
import { useSignupData, useTranslation } from "hooks"
import { LanguageContext } from "context/LanguageContext"
import { Context } from "pages/_app"
import * as Sentry from "@sentry/react"
import AuExperienceQuestions from "components/pages/signup/AuExperienceQuestions"

const LOCAL_STORAGE_LAST_PAGE = "lastPageUserWasOn"

type Props = {
  step: PrismicSignStepType
  steps: PrismicSignStepType[]
  widgetprops?: WidgetProps
  translation?: TranslationType
  validation: {
    messages: Content.ValidationmessagesDocument
  }
  setCurrentContent?: React.Dispatch<React.SetStateAction<string>>
}

const SignupStepHandler = ({ step, steps, translation, widgetprops, validation, setCurrentContent }: Props) => {
  if (!step || !steps) {
    goToPage("/signup/404")
    return null
  }
  const translations = widgetprops ? step : useTranslation(translation, step, validation.messages) || {}

  const {
    signupData,
    updateStepUid,
    savingDataLoading,
    continueToNextStep,
    stepUid,
    updateSignupData,
    updatePromptMessage,
    updateJurisdiction,
    jurisdiction
  } = useSignupData()

  const { language } = useContext(LanguageContext)
  const { validationMessages } = useContext(Context)

  const { data, uid } = (translations as PrismicSignStepType) || {}
  const [isUserLoading, setIsUserLoading] = useState(false)
  const { sections, prevSteps } = getPreviousStepsAndSections(steps)
  const prevStep = data.disableBack ? null : prevSteps[uid]
  const currentSection = data.section
  const cxdRef = getCxdRef()

  useEffect(() => {
    if (window.location.search.includes("callback")) {
      setIsUserLoading(true)
      fetchUser()
    }
    if (signupData) {
      Sentry.addBreadcrumb({
        category: "signup",
        message: `${signupData["welcome"]?.email || "New user"} entered ${stepUid}`,
        level: "info"
      })
      Sentry.setContext("signupData", signupData)
    }
    if (jurisdiction) {
      Sentry.setTag("jurisdiction", jurisdiction)
    }
  }, [])

  const updateSignupAndContinue = async (signupInfo, extraData = {}, redirect = false) => {
    let redirected = false
    const lastStep = getLastPageUserWasOn(signupInfo?.lastPageUserWasOn)
    if (signupInfo && signupInfo.lastPageUserWasOn) {
      setLocalStorage(LOCAL_STORAGE_LAST_PAGE, lastStep)
      redirected = true
      goToPage(`/signup/${lastStep}`)
    }
    if (signupInfo && signupInfo.application_completed && signupInfo.application_completed === "yes") {
      userExistsRedirect(signupInfo)
      redirected = true
    }
    if (redirected) {
      updateSignupData({ ...signupInfo, lastPageUserWasOn: lastStep })
    } else {
      continueToNextStep(data.slices, { ...extraData, ...signupInfo, lastPageUserWasOn: lastStep }, false, redirect)
    }
  }

  // Handle socials
  useEffect(() => {
    const startSignup = async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      incompleteSignup: { [key: string]: any },
      email: string
    ) => {
      setIsUserLoading(true)

      if (incompleteSignup && incompleteSignup.last_page_user_was_on) {
        if (incompleteSignup.application_completed !== "yes") {
          if (Boolean(incompleteSignup?.last_page_user_was_on)) {
            const lastPage = getLastPageUserWasOn(incompleteSignup.last_page_user_was_on)
            const url = new URL(window.location.href)
            if (url.searchParams.has("callback")) {
              url.searchParams.delete("callback") // remove callback from url
              window.history.replaceState(null, "", url.toString()) // update url without reloading the page
            }
            updateSignupAndContinue({ ...incompleteSignup, last_page_user_was_on: lastPage }, true)
          } else {
            updateSignupData({
              welcome: {
                email: email
              }
            })
          }
        }
      } else {
        updateSignupData({
          welcome: {
            email: email
          }
        })
      }
      setIsUserLoading(false)
    }

    const userExists = (errorMessage: string) => {
      goToPage("/signup/welcome")
      updatePromptMessage({
        message: errorMessage,
        success: false
      })
      setIsUserLoading(false)
    }

    if (stepUid === "country-of-residence") {
      checkIfUserIsValid(startSignup, userExists, validationMessages, setIsUserLoading)
    }
  }, [stepUid])

  // Affiliates
  useEffect(() => {
    if (cxdRef) {
      if (signupData && signupData.welcome && !signupData.welcome.cxd) {
        const addCxdToWelcome = {
          ...signupData,
          welcome: {
            ...signupData.welcome,
            ...(cxdRef ? { cxdRef: cxdRef } : {}) // Adding cxd to welcome step object
          }
        }
        Sentry.addBreadcrumb({
          category: "affiliates",
          message: `Adding cxdRef ${cxdRef} to signup data`,
          level: "info"
        })
        updateSignupData(addCxdToWelcome)
      }
    }

    setTimeout(() => {
      // UTM
      const utmDetailsFromParameters = getUTMParameters()
      const utmDetailsFromCache = getUTMParametersCache()
      if (!utmDetailsFromCache && utmDetailsFromParameters) {
        saveUTMParametersToCache(utmDetailsFromParameters)
      }
      const utmSource = utmDetailsFromCache?.utm_source || utmDetailsFromParameters.utm_source
      const utmMedium = utmDetailsFromCache?.utm_medium || utmDetailsFromParameters.utm_medium
      const utmCampaign = utmDetailsFromCache?.utm_campaign || utmDetailsFromParameters.utm_campaign
      const utmContent = utmDetailsFromCache?.utm_content || utmDetailsFromParameters.utm_content
      const utmTerm = utmDetailsFromCache?.utm_term || utmDetailsFromParameters.utm_term

      const utmDetails = {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm
      }
      Sentry.setContext("utmDetails", utmDetails)

      // GTM
      const firstClick = getCookie("firstClick")
      const lastClick = getCookie("lastClick")
      const clickCount = getCookie("clickCount")
      const gclidValue = getCookie("gclidValue")

      const gtmDetails = {
        first_click: getFieldValue(signupData, "first_click") || firstClick,
        last_click: lastClick,
        click_count: clickCount,
        gclid_value: gclidValue
      }
      Sentry.setContext("gtmDetails", gtmDetails)

      const addUTMAndGtmToWelcome = {
        ...signupData,
        welcome: {
          ...signupData?.welcome,
          ...utmDetails,
          ...gtmDetails
        }
      }

      if (utmSource === "tradingview") {
        addUTMAndGtmToWelcome["source-question"] = {
          other_selected: false,
          source_question: `source_question_${utmSource}`,
          source_question_affiliate_referral_name: "",
          source_question_label: utmSource
        }
      }
      if (Object.values({ ...utmDetails, gtmDetails }).some((value) => value)) {
        updateSignupData(addUTMAndGtmToWelcome)
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (uid !== "welcome") {
      let linkToRedirect = ""
      if (signupData && "last_page_user_was_on" in signupData) {
        linkToRedirect = signupData.last_page_user_was_on?.toString()
      }

      linkToRedirect = getLastPageUserWasOn(linkToRedirect)
      if (getLocalStorage(LOCAL_STORAGE_LAST_PAGE)) {
        linkToRedirect = getLocalStorage(LOCAL_STORAGE_LAST_PAGE)
      }

      if (linkToRedirect && !linkToRedirect.includes(uid)) {
        goToPage(`/signup/${linkToRedirect}`)
      }

      if (signupData && "country-of-residence" in signupData) {
        const jurisdiction = signupData["country-of-residence"].jurisdiction
        updateJurisdiction(jurisdiction)
      }
    }
  }, [])

  useEffect(() => {
    Router.beforePopState(({ as }) => {
      updateLastVisitedStep(signupData, as.replace("/signup/", "").replace(/\?.+/, ""), language)
      return true
    })

    return () => {
      Router.beforePopState(() => true)
    }
  }, [Router])

  useEffect(() => {
    updateStepUid(uid)
  }, [uid])

  if (savingDataLoading || isUserLoading) {
    return <Loading />
  }

  const content = {
    ...data,
    uid,
    prevStep,
    sections,
    currentSection,
    translation
  }

  if (widgetprops) {
    return <SignupWelcome {...content} widgetprops={widgetprops} setCurrentContent={setCurrentContent} />
  }
  let switchComponent

  switch (uid) {
    case "welcome":
      switchComponent = <SignupWelcome {...content} />
      break
    case "phone-number-verification":
      switchComponent = <PhoneNumberVerification {...content} />
      break
    case "two-factor-code":
      switchComponent = <TwoFactorCode {...content} />
      break
    case "teo-factor-success":
      switchComponent = <TwoFactorSuccess {...content} />
      break
    case "two-factor-fail":
      switchComponent = <TwoFactorFail {...content} />
      break
    case "platform-selection":
      switchComponent = <PlatformSelection {...content} />
      break
    case "mt4-password":
      switchComponent = <PlatformMT4Password {...content} />
      break
    case "account-currency":
      switchComponent = <AccountCurrency {...content} />
      break
    case "sumsub-verification":
      switchComponent = <SumSubVerification {...content} />
      break
    case "asset-worth":
      switchComponent = <AssetWorth {...content} />
      break
    case "first-deposit":
      switchComponent = <FirstDeposit {...content} />
      break
    case "experience-questions":
    case "knowledge-questions":
    case "qualifier-questions":
      switchComponent = (
        <ExperienceAndKnowledgeQuestions {...content} isExperienceQuestions={uid === "experience-questions"} />
      )
      break
    case "experience-questions-au-1":
    case "experience-questions-au-2":
      switchComponent = (
        <AuExperienceQuestions
          {...content}
          isExperienceQuestions={uid === "experience-questions-au-1" || uid === "experience-questions-au-2"}
        />
      )
      break

    case "declined-account":
      switchComponent = <DeclinedAccount {...content} />
      break
    case "terms-and-conditions":
      switchComponent = <TermsAndConditions {...content} />
      break
    case "source-question":
      switchComponent = <SourceQuestion {...content} uid={uid} />
      break
    default:
      switchComponent = <SignupStepBase {...content} />
      break
  }

  return switchComponent
}

export default SignupStepHandler
