import React, { useEffect } from "react"
import { RichTextField } from "@prismicio/types"
import { BottomArea, InfoBox } from "components"
import TopTextInfo from "components/pages/signup/Common/TopTextInfo"
import Fields from "components/pages/signup/Common/Fields"
import { SignupStepType, getInfoModal, isRichTextValid, validObject } from "utils"
import { useFieldConditionalModal, useSignupData } from "hooks"

type Props = SignupStepType & {
  onContinueClick?: () => void
  skipUpdateSignupData?: boolean
}

const SignupStepBase = (props: Props) => {
  const { currentStepSignupData, initializeStep, getCurrentSignupData, currentStepNextData } = useSignupData()

  const {
    slices,
    icon,
    title,
    description,
    textAlign,
    canSkip,
    prevStep,
    bottomInfo,
    onContinueClick,
    skipUpdateSignupData = false
  } = props

  useEffect(() => {
    initializeStep(slices, skipUpdateSignupData)
  }, [])

  // Info Modals
  // These are modals that come from prismic
  const infoModal = getInfoModal(slices)
  const { initializeModal, updateModalStatus, displayModal } = useFieldConditionalModal()

  useEffect(() => {
    if (infoModal) {
      initializeModal(infoModal, getCurrentSignupData())
    }
  }, [infoModal])

  useEffect(() => {
    if (infoModal) {
      updateModalStatus(infoModal, getCurrentSignupData())
    }
  }, [currentStepSignupData, currentStepNextData])

  let fieldsAreaMarginTop = 0
  if (validObject(icon) && !isRichTextValid(title) && !isRichTextValid(description)) {
    fieldsAreaMarginTop = -40
  }
  const newTitle = title.slice(0, 1)

  return (
    <>
      <TopTextInfo textAlign={textAlign} icon={icon} title={newTitle as RichTextField} description={description} />
      <Fields slices={slices} marginTop={fieldsAreaMarginTop} />
      <InfoBox defaultText={bottomInfo} />
      <BottomArea slices={slices} prevStep={prevStep} canSkip={canSkip} onContinueClick={onContinueClick} />
      {displayModal()}
    </>
  )
}

export default SignupStepBase
