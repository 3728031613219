import React, { useState, useEffect } from "react"
import { Loading, FieldVariation, SliceNameType } from "components"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import {
  CURRENT_STEP_NUMBER_OF_PARTS,
  CURRENT_STEP_PART,
  ObjectWithFieldsType,
  SignupStepType,
  SliceType,
  getFieldValue,
  getFieldsAndInfoBoxes,
  showField
} from "utils"
import { useScoreCheck, useSignupData } from "hooks"

type Props = SignupStepType & {
  isExperienceQuestions?: boolean
}

const QUESTIONS_INDEXES = "questions_indexes"

const ExperienceAndKnowledgeQuestions = (props: Props) => {
  const {
    currentStepPart,
    currentStepNumberOfParts,
    updateCurrentStepPart,
    getCurrentSignupData,
    updateCurrentStepSignupData,
    stepUid,
    signupData
  } = useSignupData()
  const { scoreCheckValidation, isLoading } = useScoreCheck()

  const { slices, isExperienceQuestions, uid } = props
  const [finalSlices, setFinalSlices] = useState<SliceType[]>([])

  useEffect(() => {
    const getFinalSlices = () => {
      const questions = slices.filter((slice) => slice.variation === FieldVariation.CardGroup)
      const numberOfQuestions = questions.length
      const questionAmount = getFieldValue(getCurrentSignupData(), "question_amount")

      const totalQuestionsToShow = questionAmount ? Number(questionAmount) : numberOfQuestions

      const { fields } = getFieldsAndInfoBoxes(slices)
      const answeredQuestions = fields.reduce((acc: ObjectWithFieldsType, field) => {
        const { fieldName } = field
        return {
          ...acc,
          [fieldName]: getFieldValue(getCurrentSignupData(), fieldName)
        }
      }, {})

      if (!isExperienceQuestions) {
        // Knowledge / Qualifier Questions
        const stepPartData = {
          [CURRENT_STEP_NUMBER_OF_PARTS]: totalQuestionsToShow,
          [CURRENT_STEP_PART]: signupData[stepUid] ? signupData[stepUid][CURRENT_STEP_PART] || 0 : 0
        }

        if (totalQuestionsToShow === numberOfQuestions) {
          // Return all questions
          updateCurrentStepSignupData({
            ...answeredQuestions,
            ...stepPartData
          })
          return slices
        }

        const questionsIndexes = getFieldValue(getCurrentSignupData(), QUESTIONS_INDEXES)?.split(",")

        const indexes = []

        if (questionsIndexes.length === totalQuestionsToShow) {
          questionsIndexes.forEach((q) => {
            indexes.push(Number(q))
          })

          updateCurrentStepSignupData({
            ...answeredQuestions,
            ...stepPartData,
            [QUESTIONS_INDEXES]: indexes.toString()
          })
        } else {
          const getRandomIndex = () => {
            let value = Math.floor(Math.random() * numberOfQuestions)
            while (indexes.includes(value)) {
              value = Math.floor(Math.random() * numberOfQuestions)
            }
            indexes.push(value)
          }

          // Select <questionAmount> random questions
          for (let i = 0; i < totalQuestionsToShow; i += 1) {
            getRandomIndex()
          }

          updateCurrentStepSignupData({
            [QUESTIONS_INDEXES]: indexes.toString(),
            ...stepPartData
          })
        }

        return [
          ...indexes.map((index) => questions[index]),
          ...slices.filter((slice) => slice.slice_type === SliceNameType.ActionButton)
        ]
      }

      // Experience Questions
      const validFields = fields.filter((field) => showField(field, signupData))
      updateCurrentStepSignupData({
        ...answeredQuestions,
        [CURRENT_STEP_NUMBER_OF_PARTS]: validFields.length,
        [CURRENT_STEP_PART]: signupData[stepUid] ? signupData[stepUid][CURRENT_STEP_PART] || 0 : 0
      })
      return slices
    }

    setFinalSlices(getFinalSlices())
  }, [slices, stepUid])

  const onContinueClick = () => {
    if (currentStepPart < currentStepNumberOfParts - 1) {
      updateCurrentStepPart(currentStepPart + 1)
    } else {
      scoreCheckValidation(slices, uid)
    }
  }

  return (
    <>
      <SignupStepBase {...props} slices={finalSlices} onContinueClick={onContinueClick} skipUpdateSignupData />
      {isLoading && <Loading />}
    </>
  )
}

export default ExperienceAndKnowledgeQuestions
