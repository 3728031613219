import React, { useMemo } from "react"
import SignupStepBase from "components/pages/signup/SignupStepBase"
import {
  getCxdRef,
  getFieldValue,
  isDevEnvironment,
  isFinEnvironment,
  isStageEnvironment,
  shuffleArray,
  SignupStepType
} from "utils"
import { useSignupData } from "hooks"

const SourceQuestion = (props: SignupStepType) => {
  const { continueToNextStep, signupData } = useSignupData()
  const { slices, canSkip } = props
  const cxdRef = getCxdRef()
  const countryCode = getFieldValue(signupData, "country_of_residence")

  const onContinueClick = () => {
    const extraData =
      countryCode === "IN" && cxdRef?.startsWith("37067_")
        ? {
            ["country-of-residence"]: {
              ...signupData["country-of-residence"],
              brand_id: "sd"
            }
          }
        : {}
    if (isFinEnvironment() || isStageEnvironment() || isDevEnvironment()) {
      continueToNextStep(slices, extraData)
    } else {
      continueToNextStep(slices)
    }
  }

  const slicesWithShuffledSources = useMemo(() => {
    const newSlices = slices.map((slice) => {
      if (slice.variation === "cardGroup") {
        return {
          ...slice,
          items: shuffleArray([...slice.items])
        }
      }
      return slice
    })
    return newSlices
  }, [slices])

  return (
    <SignupStepBase {...props} slices={slicesWithShuffledSources} onContinueClick={onContinueClick} canSkip={canSkip} />
  )
}

export default SourceQuestion
