import React, { useEffect } from "react"
import { CircularProgress } from "@mui/material"
import { RichText, Icon } from "components"
import { PageContainer, PageContentCenterAligned } from "styles"
import { goToPage, PrismicSignStepType } from "utils"

const TwoFactorSuccess = (props: PrismicSignStepType["data"]) => {
  const { icon, title } = props

  useEffect(() => {
    setTimeout(() => {
      goToPage("/account/overview")
    }, 2000)
  }, [])

  return (
    <PageContainer>
      <PageContentCenterAligned>
        <Icon icon={icon} />
        <RichText field={title} />
        <CircularProgress />
      </PageContentCenterAligned>
    </PageContainer>
  )
}

export default TwoFactorSuccess
