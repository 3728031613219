import React, { useState, useMemo, useEffect } from "react"
import { Field, FieldVariation, RichText, BottomArea, InfoBoxType, Icon, Text, InfoBox } from "components"
import { InfoBoxesArea, accountTypeTextStyle } from "components/mt4/MT4AccountBoxes.styled"
import { PageContainer, darkBlueColor, lightGreyColor, whiteColor } from "styles"
import {
  ObjectWithFieldsType,
  LOCAL_STORAGE_PLATFORM_CURRENCIES,
  PlatformCurrencyType,
  SignupStepType,
  getCxdRef,
  getFieldValue,
  getFieldsAndInfoBoxes,
  getLocalStorage,
  removeUrlParam,
  LOCAL_STORAGE_CXD,
  MT4UserGroupAndTypeType
} from "utils"
import { useMobileMode, useSignupData } from "hooks"
import { Content, ImageContainer, InfoContent, DescriptionContainer } from "./PlatformMT4Password.styled"

const PlatformMT4Password = (props: SignupStepType) => {
  const isMobile = useMobileMode()
  const { signupData, continueToNextStep, bottomAreaHeight, updateCurrentStepSignupData } = useSignupData()

  const { slices, prevStep, uid, description, icon } = props
  const { fields, infoBoxes } = getFieldsAndInfoBoxes(slices)
  const mt4PasswordField = fields.find((slice) => slice.variation === FieldVariation.PasswordTextfield)

  const mt4PasswordSlice = slices.find((slice) => slice.slice_type === "mt4_password")

  const [data, setData] = useState({
    [mt4PasswordField.fieldName]: "",
    [infoBoxes[0].fieldName]: ""
  })

  // Password
  const onPasswordChange = (newPassword: ObjectWithFieldsType) => {
    setData({
      ...data,
      [mt4PasswordField.fieldName]: newPassword[mt4PasswordField.fieldName]
    })
  }

  // Account type
  const updateAccountType = (fieldName: string, fieldValue: string) => {
    setData({
      ...data,
      [fieldName]: fieldValue
    })
  }

  useEffect(() => {
    updateCurrentStepSignupData(data)
  }, [data])

  // Affiliates
  const cxdRef = getCxdRef()
  const currencies: PlatformCurrencyType = getLocalStorage(LOCAL_STORAGE_PLATFORM_CURRENCIES)
  const platform = getFieldValue(signupData, "platform")
  const currency = getFieldValue(signupData, "currency")
  const platformCurrency = getFieldValue(signupData, "platform_currency")

  const finalInfoBoxes: InfoBoxType[] = useMemo(() => {
    const selectedCurrency: MT4UserGroupAndTypeType[] = currencies
      ? currencies[currency]
      : platformCurrency[platform]?.currency[currency]

    if (!selectedCurrency || selectedCurrency.length === 0) {
      return []
    }

    if (selectedCurrency.length === 1) {
      return infoBoxes.filter((box) => box.fieldValue === selectedCurrency[0].type)
    }

    if (selectedCurrency.length > 1) {
      const selectedCurrencyTypes = selectedCurrency.map((c) => c.type)
      return infoBoxes.filter((box) => selectedCurrencyTypes.includes(box.fieldValue))
    }
  }, [currencies, slices])

  useEffect(() => {
    if (finalInfoBoxes.length > 0 && !data[infoBoxes[0].fieldName]) {
      const selectedType = getFieldValue(signupData, finalInfoBoxes[0].fieldName)
      const finalInfoBoxesTypes = finalInfoBoxes.map((box) => box.fieldValue)

      updateAccountType(
        infoBoxes[0].fieldName,
        finalInfoBoxesTypes.includes(selectedType) ? selectedType : finalInfoBoxes[0].fieldValue
      )
    }
  }, [finalInfoBoxes])

  const onContinueClick = () => {
    if (cxdRef) {
      removeUrlParam(LOCAL_STORAGE_CXD)
    }

    continueToNextStep(slices, { [uid]: { ...data } })
  }

  return (
    <PageContainer gap="0" padding="0" backgroundColor={isMobile ? lightGreyColor : "transparent"} overflow="hidden">
      <Content mobile={isMobile}>
        <ImageContainer>
          <Icon icon={icon} />
        </ImageContainer>
        <DescriptionContainer>
          <RichText field={description} />
        </DescriptionContainer>
        <InfoContent
          padding={isMobile ? `60px 20px ${bottomAreaHeight}px 20px` : `20px 20px ${bottomAreaHeight}px 20px`}
          mobile={isMobile}
        >
          <Field
            variation={FieldVariation.PasswordTextfield}
            currentValue={data[mt4PasswordField.fieldName]}
            {...mt4PasswordField}
            handleChange={onPasswordChange}
          />
          {finalInfoBoxes.length > 1 && (
            <InfoBoxesArea>
              <Text text={mt4PasswordSlice?.primary.selectAccountTypeText} sx={accountTypeTextStyle} />
              {finalInfoBoxes.map((infoBox) => (
                <InfoBox
                  key={infoBox.fieldValue}
                  onClick={() => updateAccountType(infoBox.fieldName, infoBox.fieldValue)}
                  {...infoBox}
                  defaultTitleColor={
                    data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultTitleColor : darkBlueColor
                  }
                  defaultTextColor={
                    data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultTextColor : darkBlueColor
                  }
                  defaultBackgroundColor={
                    data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultBackgroundColor : whiteColor
                  }
                  border={`1px solid ${infoBox.defaultBackgroundColor}`}
                />
              ))}
            </InfoBoxesArea>
          )}
        </InfoContent>
      </Content>
      <BottomArea slices={slices} onContinueClick={onContinueClick} prevStep={prevStep} />
    </PageContainer>
  )
}

export default PlatformMT4Password
