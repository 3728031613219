import React, { useEffect, useState } from "react"
import { RichText, ActionButton, PlatformInfoType, ActionButtonActionType, TextWithImages } from "components"
import { PageContainer } from "styles"
import { DynamicSrcType, PlatformBackendCurrencyType, SliceType, hexToRgb } from "utils"
import { useMobileOrTabletMode, useSignupData } from "hooks"
import {
  ContentContainer,
  TitleContainer,
  ButtonsContainer,
  changeButtonStyle,
  continueButtonStyle
} from "./PlatformSelected.styled"
import PlatformBanner from "./PlatformBanner"

type Props = {
  selectedPlatform: PlatformInfoType
  platforms: PlatformInfoType[]
  getBackground: (topColor: string, bottomColor: string, transparentHeight: number) => string
  unselectPlatform: () => void
  slices: SliceType[]
  buttonText: string
  currency: PlatformBackendCurrencyType
}

const PlatformSelected = ({
  selectedPlatform,
  platforms,
  getBackground,
  unselectPlatform,
  slices,
  buttonText,
  currency
}: Props) => {
  const [dynamicSrc, setDynamicSrc] = useState<DynamicSrcType | null>(null)
  const isMobileOrTablet = useMobileOrTabletMode()
  const { continueToNextStep, stepUid } = useSignupData()

  const {
    title,
    description,
    textColor,
    image,
    backgroundTopColor,
    backgroundBottomColor,
    fieldName,
    value,
    changeText,
    backend_id: backendId
  } = selectedPlatform

  const onContinueHandler = () => {
    continueToNextStep(slices, {
      [stepUid]: { [fieldName]: value, type: "live", backend_id: backendId },
      platform_currencies: { platform_currency: currency }
    })
  }

  const displayButtons = () => {
    return (
      <ButtonsContainer
        backgroundTopColor={hexToRgb(backgroundBottomColor)}
        backgroundBottomColor={backgroundBottomColor}
        isMobileOrTablet={isMobileOrTablet}
      >
        {platforms.length > 1 && isMobileOrTablet && (
          <ActionButton displayText={changeText || "Change"} onClick={unselectPlatform} sx={changeButtonStyle} />
        )}
        <ActionButton
          displayText={buttonText || "Continue"}
          onClick={onContinueHandler}
          sx={continueButtonStyle}
          actionType={ActionButtonActionType.Continue}
        />
      </ButtonsContainer>
    )
  }

  const handleLoaded = () => {
    if (isMobileOrTablet) {
      setDynamicSrc({
        url: image.mobile.url,
        dimensions: image.mobile.dimensions
      })
    } else {
      setDynamicSrc({
        url: image.desktop.url,
        dimensions: image.desktop.dimensions
      })
    }
  }

  const checkDeviceUpdateSources = () => {
    if (isMobileOrTablet) {
      setDynamicSrc({
        url: image.mobile_blur.url,
        dimensions: image.mobile_blur.dimensions
      })
    } else {
      setDynamicSrc({
        url: image.desktop_blur.url,
        dimensions: image.desktop_blur.dimensions
      })
    }
  }

  useEffect(() => {
    checkDeviceUpdateSources()
  }, [value])

  return (
    <PageContainer
      padding="0"
      margin={isMobileOrTablet ? "auto" : "30px auto"}
      textColor={textColor}
      maxWidth={isMobileOrTablet ? "100%" : "600px"}
    >
      {image && dynamicSrc && (
        <PlatformBanner
          onLoad={handleLoaded}
          image={dynamicSrc}
          alt="Platform"
          borderTopLeftRadius={isMobileOrTablet ? "0" : "20px"}
          borderTopRightRadius={isMobileOrTablet ? "0" : "20px"}
        />
      )}
      <ContentContainer
        background={getBackground(backgroundTopColor, backgroundBottomColor, 200)}
        isMobileOrTablet={isMobileOrTablet}
        textColor={textColor}
      >
        <TitleContainer>
          <RichText field={title} />
        </TitleContainer>
        <TextWithImages description={description} useTradingViewLogo />
        {!isMobileOrTablet && displayButtons()}
      </ContentContainer>
      {isMobileOrTablet && displayButtons()}
    </PageContainer>
  )
}

export default PlatformSelected
