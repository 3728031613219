import styled from "@emotion/styled"
import { darkBlueColor, whiteColor } from "styles"

export const Content = styled.div<{ mobile: boolean }>`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.mobile ? "1" : "initial")};
  margin-bottom: 20px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 150px;
`

export const DescriptionContainer = styled.div`
  padding: 0 20px 50px 20px;
`

export const Separator = styled.div`
  width: 1px;
  height: 50px;
  background-color: ${darkBlueColor};
`

export const InfoContent = styled.div<{ padding: string; mobile: boolean }>`
  background-color: ${whiteColor};
  flex: ${(props) => (props.mobile ? "1" : "initial")};
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding};
  gap: 50px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  margin-bottom: ${(props) => (props.mobile ? "0" : "20px")};
`
