import React, { Fragment } from "react"
import { RichTextField } from "@prismicio/types"
import { PlatformCard, Text, RichText, PlatformInfoType } from "components"
import { PageContainer, PageTitleAndDescription } from "styles"
import { PlatformsContainer, PlatformSeparator } from "./PlatformsList.styled"

type Props = {
  platforms: PlatformInfoType[]
  title: RichTextField
  description: RichTextField
  getBackground: (topColor: string, bottomColor: string, transparentHeight: number) => string
  selectPlatform: (platform: PlatformInfoType) => void
}

const PlatformsList = ({ platforms, title, description, getBackground, selectPlatform }: Props) => {
  return (
    <PageContainer>
      <PageTitleAndDescription>
        <RichText field={title} />
        <RichText field={description} />
        <PlatformsContainer>
          {platforms.map((platform, index) => (
            <Fragment key={platform.value}>
              <PlatformCard
                image={platform.image}
                title={platform.summarisedTitle}
                description={platform.summarisedDescription}
                textColor={platform.textColor}
                background={getBackground(platform.backgroundTopColor, platform.backgroundBottomColor, 180)}
                onClick={() => selectPlatform(platform)}
              />
              {index === 0 && (
                <PlatformSeparator>
                  <Text text="Our other trading platforms" />
                </PlatformSeparator>
              )}
            </Fragment>
          ))}
        </PlatformsContainer>
      </PageTitleAndDescription>
    </PageContainer>
  )
}

export default PlatformsList
