import React, { useEffect } from "react"
import { Collapse } from "@mui/material"
import { Field, InfoBox, AllFieldsType, InfoBoxType, SliceNameType, FieldVariation, CheckboxType } from "components"
import {
  ObjectWithFieldsType,
  SliceType,
  getFieldValue,
  getFieldsAndInfoBoxes,
  getHiddenFields,
  showField
} from "utils"
import { useSignupData } from "hooks"
import { FieldsContainer } from "./Fields.styled"

type Props = {
  slices: SliceType[]
  marginTop?: number
  marginBottom?: number
}

const Fields = ({ slices, marginTop = 0, marginBottom }: Props) => {
  // console.log(slices)
  const {
    currentStepSignupData,
    updateCurrentStepSignupData,
    getCurrentSignupData,
    currentStepPart,
    currentStepNumberOfParts,
    bottomAreaHeight
  } = useSignupData()

  const { fieldsAndInfoBoxes } = getFieldsAndInfoBoxes(slices)
  const hiddenFields = getHiddenFields(slices)
  const signupData = getCurrentSignupData(false)

  useEffect(() => {
    // Uncheck checkboxes which are not visible when signupData is updated
    fieldsAndInfoBoxes
      .filter((field) => field.variation === FieldVariation.Checkbox)
      .forEach((fieldData: CheckboxType) => {
        if (!showField(fieldData, signupData)) {
          const fieldValue = getFieldValue(signupData, fieldData.fieldName)
          if (fieldValue === fieldData.value) {
            updateCurrentStepSignupData({
              ...currentStepSignupData,
              [fieldData.fieldName]: ""
            })
          }
        }
      })
  }, [signupData])

  // Hidden fields
  const addHiddenFieldsToSignupData = (currentSignupData: ObjectWithFieldsType) => {
    return hiddenFields.reduce((acc: ObjectWithFieldsType, hiddenField) => {
      let fieldValue: ObjectWithFieldsType = {}
      hiddenField.options.forEach((option) => {
        const fieldsWithNoSpaces = option.conditionalField.replaceAll(" ", "").split(",")
        const values = option.conditionalValue.replaceAll(" ", "").split(",")
        let applyValue = true
        fieldsWithNoSpaces.forEach((field, index) => {
          if (index < values.length) {
            if (currentSignupData[field] !== values[index]) {
              applyValue = false
            }
          }
        })
        if (applyValue) {
          fieldValue = { [hiddenField.fieldName]: option.value }
        }
      })
      if (Object.keys(fieldValue).length === 0) {
        fieldValue = { [hiddenField.fieldName]: hiddenField.defaultValue }
      }
      return { ...acc, ...fieldValue }
    }, {})
  }

  const InfoBoxComponent = (props: InfoBoxType & { fieldIndex: number }) => {
    return props.displayAtBottom ? null : <InfoBox key={JSON.stringify(props)} signupData={signupData} {...props} />
  }

  const displayFieldsAndInfoBoxes = () => {
    let fieldIndex = -1

    return fieldsAndInfoBoxes.map((fieldData: AllFieldsType | InfoBoxType, i) => {
      if (fieldData.sliceType === SliceNameType.InfoBox) {
        // Info Box
        return <InfoBoxComponent fieldIndex={fieldIndex} key={i} {...fieldData} />
      }

      if (!showField(fieldData, signupData)) {
        return null
      }

      const isCardGroup = currentStepNumberOfParts > 1
      fieldIndex += 1

      if (isCardGroup && currentStepPart !== fieldIndex) {
        return null
      }

      const handleChange = (newValues: ObjectWithFieldsType) => {
        // Clear the fields' value which visibility depends on this field current value
        const clearedFields = fieldData.controlledFieldsDisplay.reduce(
          (acc: ObjectWithFieldsType, field) => ({ ...acc, [field]: "" }),
          {}
        )

        const newData = {
          ...currentStepSignupData,
          ...clearedFields,
          ...newValues
        }

        updateCurrentStepSignupData({
          ...newData,
          ...addHiddenFieldsToSignupData(newData)
        })
      }

      return (
        <Collapse
          key={fieldData.fieldName}
          timeout={{
            enter: 0,
            exit: 0
          }}
          easing={{
            enter: "linear",
            exit: "linear"
          }}
        >
          <Field
            {...fieldData}
            currentValue={currentStepSignupData[fieldData.fieldName] || ""}
            handleChange={handleChange}
            signupData={signupData}
          />
        </Collapse>
      )
    })
  }

  if (!currentStepNumberOfParts) {
    return null
  }

  const containerMarginBottom = marginBottom || bottomAreaHeight

  return (
    <FieldsContainer margin={`${marginTop}px 0 ${containerMarginBottom - 10}px 0`}>
      {displayFieldsAndInfoBoxes()}
    </FieldsContainer>
  )
}

export default Fields
