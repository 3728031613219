import React, { useEffect, useState } from "react"
import { RichTextField } from "@prismicio/types"
import { BottomArea, CheckboxType, Drawer, Icon, PrismicModalInfoType, RichText } from "components"
import TopTextInfo from "components/pages/signup/Common/TopTextInfo"
import { getCheckboxes, getFieldValue, getInfoModals, SignupStepType } from "utils"
import { useMobileMode, useModal, useSignupData } from "hooks"
import TermsAndConditionsCheckbox from "./TermsAndConditionsCheckbox"
import { Content, DrawerContent, iconStyle } from "./TermsAndConditions.styled"

type MobileModalProps = {
  title: RichTextField
  description: RichTextField
}

const TermsAndConditions = (props: SignupStepType) => {
  const { signupData } = useSignupData()

  const [filteredDownCheckboxes, setFilteredDownCheckboxes] = useState<CheckboxType[] | null>()
  const [filteredDownModals, setFilteredDownModals] = useState<PrismicModalInfoType["primary"][]>()
  const [mobileModalData, setMobileModalData] = useState<MobileModalProps | null>(null)
  const { icon, slices, title, description, textAlign, prevStep } = props
  const modals = getInfoModals(slices)

  const brand = getFieldValue(signupData, "brand_id")

  const mapCheckboxesToBrand = () => {
    const checkboxes = getCheckboxes(slices)
    const filt = checkboxes.filter((each) => {
      const conditionalFields = each.showConditionalField?.split(",") || []
      const conditionalValues = each.showConditionalValue?.split(",") || []

      return conditionalFields.includes("brand_id") && conditionalValues.includes(brand)
    })
    setFilteredDownCheckboxes(filt)
  }

  const mapModalOptionsToBrand = () => {
    const filt = modals.filter(
      (each) => each.showConditionalField === "brand_id" && each.showConditionalValue === brand
    )
    setFilteredDownModals(filt)
  }

  useEffect(() => {
    mapCheckboxesToBrand()
    mapModalOptionsToBrand()
  }, [slices])
  const isMobile = useMobileMode()
  const { initializeStep } = useSignupData()

  useEffect(() => {
    initializeStep(slices)
  }, [])

  const [drawerOpen, setDrawerOpen] = useState(false)

  const { openModal, displayModal } = useModal()

  const clickLinkOpenModalOnIt = (modalIndex: number) => {
    const selectedTab = filteredDownModals[modalIndex]
    if (isMobile) {
      setDrawerOpen(true)
      setMobileModalData({
        title: selectedTab.title,
        description: selectedTab.description
      })
    } else {
      // when you click a tab, it re-populates
      const menuBits = filteredDownModals.map((m) => ({
        label: m.title,
        onClick: () => {
          openModal({
            title: m.title,
            text: m.description,
            menu: menuBits,
            hasCloseIcon: true,
            textAlign: "left"
          })
        }
      }))

      // 200ms it opens the modal with updated data
      setTimeout(() => {
        openModal({
          title: selectedTab.title,
          text: selectedTab.description,
          menu: menuBits,
          hasCloseIcon: true,
          textAlign: "left"
        })
      }, 200)
    }
  }
  const onCheckboxTextClick = (clickValue: string) => {
    clickLinkOpenModalOnIt(Number(clickValue))
  }

  return (
    <>
      <Content>
        <Icon icon={icon} sx={iconStyle} />
        <TopTextInfo textAlign={textAlign} title={title} description={description} />
        {filteredDownCheckboxes &&
          filteredDownCheckboxes.map((checkbox, index) => (
            <TermsAndConditionsCheckbox
              key={`${JSON.stringify(checkbox)}-${index}`}
              checkbox={checkbox}
              onCheckboxTextClick={onCheckboxTextClick}
              props={props}
            />
          ))}
      </Content>
      <BottomArea slices={slices} prevStep={prevStep} />
      {isMobile ? (
        <Drawer open={drawerOpen} toggleDrawer={setDrawerOpen}>
          <DrawerContent>
            <RichText field={mobileModalData?.title} />
            <RichText field={mobileModalData?.description} indentedList />
          </DrawerContent>
        </Drawer>
      ) : (
        displayModal("0 0 20px 0", "justify", "14px", "18px", true)
      )}
    </>
  )
}

export default TermsAndConditions
