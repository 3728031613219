import React, { useContext, useEffect, useRef } from "react"
import { Container } from "./TrustPilot.styled"
import { LanguageContext } from "context/LanguageContext"

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, boolean: boolean) => void
    }
  }
}

const TrustPilot = () => {
  const { language } = useContext(LanguageContext)
  const ref = useRef<HTMLDivElement>(null)

  const getTrustPilotLanguageCode = (lang: string) => {
    if (lang === "de-ch") return "de-CH"
    if (lang === "en") return "en-GB"
    return "en-GB" // Default to English
  }

  const trustPilotLanguageCode = getTrustPilotLanguageCode(language.language_code)

  useEffect(() => {
    // recreate the TrustPilot widget when the language changes
    const widget = ref.current

    if (window.Trustpilot && widget) {
      widget.innerHTML = ""
      setTimeout(() => {
        window.Trustpilot.loadFromElement(widget, true)
      }, 0)
    }
  }, [trustPilotLanguageCode])

  return (
    <Container marginLeft={trustPilotLanguageCode === "de-CH" ? "-21" : "-41"}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="5e664e1f71876d00014e2203"
        data-locale={trustPilotLanguageCode}
        data-theme="dark"
        data-style-width={trustPilotLanguageCode === "de-CH" ? "100%" : "100%"}
        data-style-alignment="left"
      ></div>
    </Container>
  )
}

export default TrustPilot
