import styled from "@emotion/styled"
import { whiteColor, lightGreyColor } from "styles"

export const PlatformsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const PlatformSeparator = styled.div`
  text-align: center;
  position: relative;

  p {
    font-size: 15px;
    display: inline;
    background: ${whiteColor};
    position: relative;
    padding: 0 10px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 13px;
    height: 1px;
    background: ${lightGreyColor};
  }
`
